import { IEntityCreateFunctionProps, IEntityUpdateFunctionProps } from 'icerockdev-admin-toolkit';
import { createPointFn, getPointBuildingFn, updatePointFn } from './api';
import { POINT_ENTITY_FIELDS } from './fields';
import PointEntity from './components/PointEntity';
import { defaultFetchItemsFn, defaultGetFn } from '~/config/api';

export default (host: string) =>
  new PointEntity({
    title: 'Pronto points',
    editable: true,
    viewable: true,
    creatable: true,
    exportable: false,
    selectable: false,
    api: {
      list: { url: `${host}/v1/point`, method: 'get' },
      get: { url: `${host}/v1/point`, method: 'get' },
      create: { url: `${host}/v1/point`, method: 'post' },
      update: { url: `${host}/v1/point`, method: 'put' },
      delete: { url: `${host}/v1/point`, method: 'delete' },
    },
    menu: {
      enabled: true,
      label: 'Pronto points',
      url: '/point',
    },
    references: {
      buildingId: {
        getMany: getPointBuildingFn,
        getOne: getPointBuildingFn,
      },
    },
    fields: POINT_ENTITY_FIELDS,
    fetchItemsFn: defaultFetchItemsFn,
    getItemsFn: defaultGetFn,
    createItemsFn: ({ url, token, data }: IEntityCreateFunctionProps) =>
      createPointFn({ url, token, data }),
    updateItemsFn: ({ url, id, token, data }: IEntityUpdateFunctionProps) =>
      updatePointFn({ url, id, token, data }),
  });
