import React, {FC, useCallback} from 'react';
import {IEntityFieldProps} from 'icerockdev-admin-toolkit';
import {TextField} from '@material-ui/core';
import {isNil} from 'ramda';

type IProps = IEntityFieldProps & {};

const EntityFieldPrice: FC<IProps> = ({
                                        label,
                                        value,
                                        handler,
                                        error,
                                        isEditing,
                                        onClick,
                                        options,
                                        placeholder,
                                      }) => {
  const onChange = useCallback(
    (event) => {
      if (!handler) return;

      handler(event.target.value);
    },
    [value, handler]
  );

  const price =
    (value &&
      parseFloat(value) &&
      parseFloat(parseFloat(value).toFixed(options?.accuracy || 2)))
    || null;

  return isEditing ? (
    <div>
      <TextField
        type="number"
        label={label}
        value={isNil(value) ? '' : value}
        onChange={onChange}
        error={!!error}
        helperText={error}
        variant="outlined"
        inputProps={{step: 'any'}}
        placeholder={placeholder || ''}
      />
    </div>
  ) : (
    <div onClick={onClick}>{isNil(price) ? '' : `${price} $`}</div>
  );
};

export {EntityFieldPrice};
