import {
  IEntityCreateFunctionProps,
  IEntityFetchFunctionProps,
  IEntityGetFunctionProps,
  IEntityUpdateFunctionProps
} from 'icerockdev-admin-toolkit';
import {defaultFetchItemsFn, defaultGetFn} from "~/config/api";
import {createBuildingFn, updateBuildingFn,} from './api';
import {BUILDING_ENTITY_FIELDS} from './fields';
import BuildingEntity from "./components/BuildingEntity";

export default (host: string) => new BuildingEntity({
  title: 'Buildings',
  editable: true,
  viewable: true,
  creatable: true,
  exportable: false,
  selectable: false,
  api: {
    list: {url: `${host}/v1/building`, method: 'get'},
    get: {url: `${host}/v1/building`, method: 'get'},
    create: {url: `${host}/v1/building`, method: 'post'},
    update: {url: `${host}/v1/building`, method: 'put'},
    delete: {url: `${host}/v1/building`, method: 'delete'},
  },
  menu: {
    enabled: true,
    label: 'Buildings',
    url: '/building',
  },
  fields: BUILDING_ENTITY_FIELDS,

  fetchItemsFn: defaultFetchItemsFn,
  getItemsFn: defaultGetFn,
  createItemsFn: ({url, token, data}: IEntityCreateFunctionProps) => createBuildingFn({url, token, data}),
  updateItemsFn: ({url, id, token, data}: IEntityUpdateFunctionProps) => updateBuildingFn({url, id, token, data}),
});
