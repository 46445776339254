import { IEntityField } from 'icerockdev-admin-toolkit';
import { EntityFieldReferenceSelect } from '~/config/components/common/EntityFieldReferenceSelect';
import { ORDER_STATUSES } from '~/entity/order/constants';
import { OrderProductFieldQr } from '~/entity/order/components/OrderProductFieldQr';
import { OrderFieldImage } from '~/entity/order/components/OrderFieldImage';
import { OrderProductFieldAmount } from '~/entity/order/components/OrderProductFieldAmount';
import { EntityFieldPrice } from '~/config/components/common/EntityFieldPrice';
import { OrderFieldReferenceSelect } from '~/entity/order/components/OrderFieldReferenceSelect';
import { EntityDateField } from '~/config/components/common/EntityDateField';

export const ORDER_ENTITY_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    sortable: true,
    hideInCreate: true,
    hideInEdit: true,
  },
  {
    type: 'referenceSelect',
    component: EntityFieldReferenceSelect,
    name: 'pointId',
    label: 'Pronto point',
    hideInView: false,
    hideInList: false,
    hideInCreate: true,
    hideInEdit: true,
    required: true,
  },
  {
    type: 'referenceSelect',
    component: EntityFieldReferenceSelect,
    name: 'customerId',
    label: 'Resident',
    hideInView: false,
    hideInList: false,
    hideInCreate: true,
    hideInEdit: true,
    required: true,
  },
  {
    type: 'datetime',
    name: 'createdAt',
    label: 'Create Date',
    sortable: true,
    hideInCreate: true,
    hideInEdit: true,
    component: EntityDateField,
  },
  {
    type: 'number',
    name: 'total',
    label: 'Total amount',
    sortable: true,
    filterable: true,
    hideInView: true,
    required: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    options: ORDER_STATUSES,
    sortable: true,
    filterable: true,
    hideInView: false,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'referenceSelect',
    name: 'productList',
    label: '-',
    hideInView: true,
    hideInList: true,
    hideInEdit: true,
    hideInCreate: true,
  },
];

export const ORDER_PRODUCT_ENTITY_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    sortable: true,
  },
  {
    type: 'custom',
    component: OrderFieldImage,
    name: 'imageURL',
    label: 'Image',
    sortable: false,
  },
  {
    type: 'referenceSelect',
    component: OrderFieldReferenceSelect,
    name: 'categoryId',
    label: 'Category',
  },
  {
    type: 'string',
    name: 'name',
    label: 'Name',
    sortable: true,
  },
  {
    type: 'string',
    name: 'description',
    label: 'Description',
    sortable: true,
  },
  {
    type: 'number',
    name: 'quantity',
    label: 'Quantity',
    sortable: true,
  },
  {
    type: 'custom',
    component: OrderProductFieldQr,
    name: 'qr',
    label: 'QR',
  },
  {
    type: 'custom',
    component: EntityFieldPrice,
    name: 'price',
    label: 'Price',
    sortable: true,
  },
  {
    type: 'custom',
    component: OrderProductFieldAmount,
    name: 'amount',
    label: 'Amount',
    sortable: true,
  },
];
