import { CustomerEntity } from './components/CustomerEntity';
import { createCustomerFn, fetchBuildingFn, updateCustomerFn } from './api';
import { CUSTOMER_ENTITY_FIELDS } from './fields';
import { defaultFetchItemsFn, defaultGetFn } from '~/config/api';

export default (host: string) =>
  new CustomerEntity({
    title: 'Residents',
    editable: true,
    viewable: true,
    creatable: true,
    exportable: false,
    selectable: false,
    api: {
      list: { url: `${host}/v1/customer`, method: 'get' },
      get: { url: `${host}/v1/customer`, method: 'get' },
      create: { url: `${host}/v1/customer`, method: 'post' },
      update: { url: `${host}/v1/customer`, method: 'put' },
      status: { url: `${host}/v1/customer`, method: 'put' },
      anonymize: { url: `${host}/v1/customer`, method: 'put' },
    },
    menu: {
      enabled: true,
      label: 'Residents',
      url: '/residents',
    },
    fields: CUSTOMER_ENTITY_FIELDS,

    references: {
      buildingId: {
        getMany: fetchBuildingFn(host, '/v1/building'),
      },
    },

    fetchItemsFn: defaultFetchItemsFn,
    getItemsFn: defaultGetFn,
    updateItemsFn: updateCustomerFn,
    createItemsFn: createCustomerFn,
  });
