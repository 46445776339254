import React, { FC } from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { useEntity } from 'icerockdev-admin-toolkit';
import {InventoryDeleteButton} from "~/entity/inventory/components/InventoryDeleteButton";

interface IProps {}

const InventoryViewButtons: FC<IProps> = observer(() => {
  const entity = useEntity();

  return (
    <div className={styles.wrap}>
      <InventoryDeleteButton data={entity?.editorData} />
    </div>
  );
});

export { InventoryViewButtons };
