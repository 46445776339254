import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { exportOrder } from '../../api';
import DatePicker from './DatePicker';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

type Props = {
  selectedList: any[];
  withToken?: (req: any, args: any) => Promise<any>;
};

const ExportDropdown: FC<Props> = ({ selectedList, withToken }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setStartDate('');
    setEndDate('');
  };

  const exportData = async () => {
    const params = {
      dateStart: startDate,
      dateEnd: endDate,
      idList: selectedList,
    };
    for (const key in params) {
      if (params[key] === '' || params[key] === 'Invalid date') {
        delete params[key];
      }
    }
    await exportOrder(withToken, params);
    handleClose();
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>Export</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Set the dates</DialogTitle>
        <DialogContent>
          <div className={classes.container}>
            <DatePicker setChange={setStartDate} label="Start Date" />
            <DatePicker setChange={setEndDate} label="End Date" end />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={exportData} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExportDropdown;
