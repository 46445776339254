import {IEntityCreateFunction, IEntityUpdateFunction, UNAUTHORIZED} from 'icerockdev-admin-toolkit';
import Axios from 'axios';
import HttpStatusCode from 'http-status-typed';
import {parseResponseErrorMessage} from "~/utils/error";
import {jwtExpired} from "~/utils/catcher";

export const createBuildingFn: IEntityCreateFunction = async ({url, token, data}) => {
  const response = await Axios.post(url, {
    name: data.name,
    address: data.address,
    phone: data.phone,
  }, {
    headers: {
      authorization: token,
    },
  }).catch(jwtExpired);

  if (response?.status === HttpStatusCode.UNAUTHORIZED) return {data: {}, error: UNAUTHORIZED};
  if (response?.status !== HttpStatusCode.OK || !response?.data) {
    const error = parseResponseErrorMessage(response) || `Can't create building`;

    return {data: {}, error};
  }

  return {
    data: response.data.data,
    error: '',
  };
}

export const updateBuildingFn: IEntityUpdateFunction = async ({url, id, token, data}) => {
  const response = await Axios.put(`${url}/${data.id}`, {
    name: data.name,
    address: data.address,
    phone: data.phone,
  }, {
    headers: {
      authorization: token,
    },
  }).catch(jwtExpired);

  if (response?.status === HttpStatusCode.UNAUTHORIZED) return {data: {}, error: UNAUTHORIZED};
  if (response?.status !== HttpStatusCode.OK || !response?.data) {
    const error = parseResponseErrorMessage(response) || `Can't update building`;

    return {data: {}, error};
  }

  return {
    data: response.data.data,
    error: '',
  };
}

export const setStatusBuildingFn = async ({url, token}) => {
  const result = await Axios
    .put(url, {}, {headers: {authorization: token}})
    .catch(jwtExpired);

  if (result?.status !== HttpStatusCode.OK || !result?.data) {
    const error = parseResponseErrorMessage(result) || `Error set status building`;

    return {data: {}, error};
  }

  return {
    data: result.data,
  };
};


export const deleteBuildingFn = async ({url, token}) => {
  const result = await Axios
    .delete(url, {headers: {authorization: token}})
    .catch(jwtExpired);

  if (result?.status !== HttpStatusCode.OK || !result?.data) {
    const error = parseResponseErrorMessage(result) || `Error delete building`;

    return {data: {}, error};
  }

  return {
    data: result.data,
  };
};
