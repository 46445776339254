import React, {FC, useCallback, useMemo} from 'react';
import {observer} from 'mobx-react';
import {useEntity} from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';
import {Button} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import BuildingEntity from "~/entity/building/components/BuildingEntity";

interface IProps {
  data: Record<string, any>
}

const BuildingDeleteButton: FC<IProps> = observer(({data}) => {
  const entity = useEntity<BuildingEntity>();

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (!window.confirm('Remove building?')) return;

      entity.deleteBuilding(data?.id);
    },
    [entity.deleteBuilding, data?.id]
  );

  const isViewing = useMemo(() => {
    return !window.location.pathname.match(new RegExp(`\/${data?.id}\/?`));
  }, [data?.id]);

  return isViewing ? (
    <div className={styles.wrap}>
      <IconButton color="primary" aria-label="delete" component="span" onClick={onClick}>
        <Delete/>
      </IconButton>
    </div>
  ) : (
    <Button onClick={onClick} className={styles.button} color="secondary" variant="outlined">
      Delete
    </Button>
  );
});

export {BuildingDeleteButton};
