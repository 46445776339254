import React, { FC, useCallback } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { TextField } from '@material-ui/core';
import { isNil } from 'ramda';

type IProps = IEntityFieldProps & {};

export const WholeSalePrice: FC<IProps> = ({
  value,
  label,
  handler,
  error,
  isEditing,
  placeholder,
}) => {
  const onChange = useCallback(
    (event) => {
      if (!handler) return;

      handler(event.target.value);
    },
    [value, handler]
  );
  return isEditing ? (
    <div>
      <TextField
        type="number"
        label={label}
        value={isNil(value) ? '' : value}
        onChange={onChange}
        error={!!error}
        helperText={error}
        variant="outlined"
        inputProps={{ step: 'any' }}
        placeholder={placeholder || ''}
      />
    </div>
  ) : (
    <div>{value && value + ' $'}</div>
  );
};
