import { IEntityField } from 'icerockdev-admin-toolkit';
import { POINT_STATUSES } from './constants';
import { EntityFieldReferenceSelect } from '~/config/components/common/EntityFieldReferenceSelect';
import { EntityFieldTime } from '~/config/components/common/EntityFieldTime';
import { PointDeleteButton } from '~/entity/point/components/PointDeleteButton';
import { PointInventoryLinkField } from '~/entity/point/components/PointInventoryLinkField';
import { EntityDateField } from '~/config/components/common/EntityDateField';

export const POINT_ENTITY_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    sortable: true,
    hideInCreate: true,
    hideInEdit: true,
  },
  {
    type: 'referenceSelect',
    component: EntityFieldReferenceSelect,
    name: 'buildingId',
    label: 'Building',
    hideInView: false,
    hideInList: false,
    hideInEdit: true,
    required: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    options: POINT_STATUSES,
    sortable: true,
    filterable: true,
    hideInEdit: true,
    hideInCreate: true,
    required: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Name',
    sortable: true,
    filterable: true,
    required: true,
  },
  // Fields need later
  // {
  //   type: 'boolean',
  //   name: 'delivery',
  //   label: 'Delivery',
  //   sortable: true,
  //   filterable: true,
  //   required: true,
  // },
  // {
  //   type: 'custom',
  //   component: EntityFieldTime,
  //   name: 'deliveryStartAt',
  //   label: 'Delivery start time',
  //   hideInList: true,
  //   sortable: true,
  //   filterable: true,
  // },
  // {
  //   type: 'custom',
  //   component: EntityFieldTime,
  //   name: 'deliveryEndAt',
  //   label: 'Delivery end time',
  //   hideInList: true,
  //   sortable: true,
  //   filterable: true,
  // },
  {
    type: 'number',
    name: 'tax',
    label: 'Tax',
    sortable: true,
    filterable: true,
    required: true,
  },
  {
    type: 'custom',
    name: 'Inventory',
    component: PointInventoryLinkField,
    hideInEdit: true,
    hideInCreate: true,
    hideInView: false,
  },
  {
    type: 'datetime',
    name: 'createdAt',
    label: 'Create Date',
    sortable: true,
    hideInCreate: true,
    hideInEdit: true,
    component: EntityDateField,
  },
];
