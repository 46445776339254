import { ORDER_ENTITY_FIELDS } from './fields';
import OrderEntity from './components/OrderEntity';
import { defaultFetchItemsFn, defaultGetFn } from '~/config/api';
import {
  getOrderCustomerFn,
  getOrderPointFn,
  getOrderProductListCategoriesFn,
} from '~/entity/order/api';

export default (host: string) =>
  new OrderEntity({
    title: 'Orders',
    editable: false,
    viewable: true,
    creatable: false,
    // exportable: true,
    selectable: true,
    api: {
      list: { url: `${host}/v1/order`, method: 'get' },
      get: { url: `${host}/v1/order`, method: 'get' },
      delete: { url: `${host}/v1/order`, method: 'delete' }, // Cancel order
    },
    menu: {
      enabled: true,
      label: 'Orders',
      url: '/order',
    },
    references: {
      productList: {
        getMany: getOrderProductListCategoriesFn,
      },
      pointId: {
        getMany: getOrderPointFn,
      },
      customerId: {
        getMany: getOrderCustomerFn,
      },
    },
    fields: ORDER_ENTITY_FIELDS,
    fetchItemsFn: defaultFetchItemsFn,
    getItemsFn: defaultGetFn,
  });
