import {
  ENTITY_ERRORS,
  IEntityCreateFunctionResult,
  IEntityProps,
  IEntityUpdateFunctionResult,
} from 'icerockdev-admin-toolkit';
import React, { ChangeEvent, ReactElement } from 'react';
import { action, computed, flow, observable, reaction, toJS } from 'mobx';
import { BASE_URL } from '~/config';
import { observer } from 'mobx-react';
import { InventoryEntityHead } from '~/entity/inventory/components/InventoryEntityHead';
import { InventoryBreadcrumbs as Breadcrumbs } from '~/entity/inventory/components/InventoryBreadcrumbs';
import { InventoryEntityList } from '~/entity/inventory/components/InventoryEntityList';
import { axios } from '~/utils/axios';
import { jwtExpired } from '~/utils/catcher';
import { InventoryViewButtons } from '~/entity/inventory/components/InventoryViewButtons';
import CustomEntity from '~/entity/CustomEntity';
import { ISendFileFunction } from '~/utils/types';
import { Button } from '@material-ui/core';

interface IProductEntity extends IEntityProps {
  sendFileFn: ISendFileFunction;
}

export default class InventoryEntity extends CustomEntity {
  @observable sendFileFn: ISendFileFunction | undefined = undefined;

  constructor(fields?: Partial<IProductEntity>) {
    super(fields as any);
    reaction(
      () => this.editorData?.productCatalogId,
      () => this.updateProductOptionsOne()
    );
    reaction(
      () => this.editorData?.categoryId,
      () => this.updateCategoryOptionsOne()
    );
    reaction(
      () => this.editorData?.pointId,
      () => this.updatePointOptionsOne()
    );
  }

  @action
  sendCSVFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    try {
      if (this.sendFileFn) {
        this.isLoading = true;
        await this.parent?.auth?.withToken(this.sendFileFn, {
          url: this.api?.uploadFile.url,
          file,
        });
        this.parent?.notifications.showSuccess('File successfully uploaded');
        this.fetchItems();
      } else {
        throw Error("sendFileFn wasn't found");
      }
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    } finally {
      this.isLoading = false;
    }
  };

  @action
  updateProductOptionsOne = async () => {
    if (!this.references.productCatalogId.getOne) return;

    const results = await this.references.productCatalogId.getOne(this);

    Object.assign(this.referenceData, { productCatalogId: results });
  };

  @action
  updateCategoryOptionsOne = async () => {
    if (!this.references.categoryId.getOne) return;

    const results = await this.references.categoryId.getOne(this);

    Object.assign(this.referenceData, { categoryId: results });
  };

  @action
  updatePointOptionsOne = async () => {
    if (!this.references.pointId.getOne) return;

    const results = await this.references.pointId.getOne(this);

    Object.assign(this.referenceData, { pointId: results });
  };

  @action
  onMount = () => {
    const parametrizedUrl = InventoryEntity.getParametrizedUrl(
      BASE_URL,
      '/v1/point/:pointId/inventory'
    );
    this.api = {
      list: { url: parametrizedUrl, method: 'get' },
      get: { url: parametrizedUrl, method: 'get' },
      create: { url: parametrizedUrl, method: 'post' },
      update: { url: parametrizedUrl, method: 'put' },
      delete: { url: parametrizedUrl, method: 'delete' },
      uploadFile: {
        url: parametrizedUrl,
        method: 'post',
      },
    };
    this.getFiltersFromHash();
    reaction(
      () => [this.filters, this.sortBy, this.sortDir, this.page, this.items],
      this.setFiltersWindowHash
    );
    reaction(() => [this.items, this.sortBy, this.sortDir], this.applyFilter);
    reaction(() => this.page, this.fetchItems);
    this.fetchItems();
  };

  static getParametrizedUrl = (host: string, path: string): string => {
    const regExpExecArray = /point\/(.+?)\/inventory/.exec(window.location.pathname);
    const pointId = regExpExecArray == null ? 0 : regExpExecArray[1];
    return `${host}${path}`.replace(':pointId', pointId.toString());
  };

  @action
  deleteInventory = async (id: any) => {
    try {
      const parametrizedUrl = InventoryEntity.getParametrizedUrl(
        BASE_URL,
        `/v1/point/:pointId/inventory/${id}`
      );

      await this?.parent?.auth
        ?.withToken(
          ({ token }) => axios.delete(parametrizedUrl, { headers: { authorization: token } }),
          {}
        )
        .catch(jwtExpired);

      this.fetchItems();
      this.parent?.history.push(InventoryEntity.getParametrizedUrl('', this.menu.url));
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    }
  };

  @action
  createItem = () => {
    this.updateItemInstance = flow(function* (this: CustomEntity) {
      this.isLoading = true;
      this.error = '';

      try {
        const data = toJS(this.editorData);

        if (!this.validateSubmitFields(data, true)) {
          throw new Error(ENTITY_ERRORS.INCORRECT_INPUT);
        }

        if (!this.api?.create?.url || !this.createItemsFn) {
          throw new Error(ENTITY_ERRORS.CANT_LOAD_ITEMS);
        }

        const result: IEntityCreateFunctionResult = yield this.parent?.auth?.withToken(
          this.createItemsFn,
          {
            url: this.api?.create?.url || '',
            data,
          }
        );

        if (!result || result.error)
          throw new Error(result?.error || ENTITY_ERRORS.CANT_LOAD_ITEMS);

        this.fetchItems();
        this.parent?.history.push(InventoryEntity.getParametrizedUrl('', this.menu.url));
      } catch (e) {
        this.error = e;
        this.parent?.notifications.showError(e.message);
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  updateItem = () => {
    this.updateItemInstance = flow(function* (this: CustomEntity) {
      this.isLoading = true;
      this.error = '';

      try {
        const data = toJS(this.editorData);

        if (!this.validateSubmitFields(data, false)) {
          throw new Error(ENTITY_ERRORS.INCORRECT_INPUT);
        }

        if (!this.api?.update?.url || !this.updateItemsFn) {
          throw new Error(ENTITY_ERRORS.CANT_LOAD_ITEMS);
        }

        const result: IEntityUpdateFunctionResult = yield this.parent?.auth?.withToken(
          this.updateItemsFn,
          {
            url: this.api?.update?.url || '',
            data,
          }
        );

        if (!result || result.error)
          throw new Error(result?.error || ENTITY_ERRORS.CANT_LOAD_ITEMS);

        this.fetchItems();

        if (this.parent?.history?.length && this.parent?.history.goBack) {
          this.parent?.history.goBack();
        } else if (this.parent?.history?.push) {
          this.parent?.history.push(InventoryEntity.getParametrizedUrl('', this.menu.url));
        }
      } catch (e) {
        this.parent?.notifications.showError(e.message);
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @computed
  get InventoryBreadcrumbs() {
    return observer(
      ({
        id,
        isEditing = false,
        isCreating = false,
        wrapper = false,
        buttons,
      }: {
        id?: any;
        isEditing?: boolean;
        isCreating?: boolean;
        wrapper?: boolean;
        buttons?: ReactElement;
      }) => (
        <Breadcrumbs
          data={this.editorData}
          fields={this.fields}
          id={id}
          name={this.title}
          url={this.menu.url}
          isEditing={isEditing}
          isCreating={isCreating}
          buttons={buttons}
          viewable={this.viewable}
          editable={this.canEdit}
          wrapper={wrapper}
          referenceData={this.referenceData}
        />
      )
    );
  }

  @computed
  get ListHeadButtons() {
    return observer(() => (
      <div style={{ marginRight: '10px' }}>
        <input
          style={{ display: 'none' }}
          accept="text/csv"
          id="upload-button-file"
          type="file"
          onChange={(e) => this.sendCSVFile(e)}
        />

        <label htmlFor="upload-button-file">
          <Button variant="contained" color="primary" component="span">
            Upload
          </Button>
        </label>
      </div>
    ));
  }

  @computed
  get ListHead() {
    return observer(() => (
      <InventoryEntityHead
        filterData={this.filterData}
        breadcrumbs={<this.InventoryBreadcrumbs />}
        buttons={<this.ListHeadButtons />}
        filters={this.filters}
        fields={this.fields}
        setFilters={this.setFilters}
        url={this.menu.url}
        applyFilter={this.applyFilter}
        withToken={this.parent?.auth?.withToken}
        onExport={this.exportData}
        canExport={this.exportable}
        canCreate={this.creatable && this.canCreate}
        entity={this}
      />
    ));
  }

  @computed
  get ListBody() {
    return observer(() => (
      <InventoryEntityList
        fields={this.fields}
        data={this.data}
        extra={this.ListExtra}
        isLoading={this.isLoading}
        url={this.menu.url}
        selected={this.selected}
        sortBy={this.sortBy}
        sortDir={this.sortDir}
        canView={this.viewable}
        canEdit={this.editable && this.canEdit}
        canSelect={this.selectable}
        setSelected={this.setSelected}
        onSortChange={this.setSort}
        withToken={this.parent?.auth?.withToken}
        entity={this}
      />
    ));
  }

  @computed
  get ViewerHead() {
    return observer(({ id }: { id: any }) => (
      <this.InventoryBreadcrumbs wrapper id={id} buttons={<this.ViewerHeadButtons />} />
    ));
  }

  @computed
  get EditorHead() {
    return observer(({ id }: { id: any }) => (
      <this.InventoryBreadcrumbs
        wrapper
        id={id}
        buttons={<this.EditorHeadButtons id={id} />}
        isEditing
      />
    ));
  }

  @computed
  get CreatorHead() {
    return observer(() => (
      <this.InventoryBreadcrumbs wrapper buttons={<this.CreatorHeadButtons />} isCreating />
    ));
  }

  @computed
  get ViewerHeadButtons() {
    return () => <InventoryViewButtons />;
  }
}
