import { IEntityField } from 'icerockdev-admin-toolkit';
import { EntityFieldReferenceSelect } from '~/config/components/common/EntityFieldReferenceSelect';
import { ProductDeleteButton } from '~/entity/product/components/ProductDeleteButton';
import { ProductFieldImage } from '~/entity/product/components/ProductFieldImage';
import { EntityFieldPrice } from '~/config/components/common/EntityFieldPrice';
import { minMaxLength } from '~/utils/validators';
import { WholeSalePrice } from '~/entity/product/components/WholeSalePrice';
import { EntityDateField } from '~/config/components/common/EntityDateField';

const MIN_NAME_LENGTH = 1;
const MAX_NAME_LENGTH = 200;
const MIN_DESCRIPTION_LENGTH = 1;
const MAX_DESCRIPTION_LENGTH = 200;

export const PRODUCT_ENTITY_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    sortable: true,
    hideInCreate: true,
    hideInEdit: true,
  },
  {
    type: 'custom',
    component: ProductFieldImage,
    name: 'imageFile',
    label: 'Image',
    filterable: false,
    sortable: false,
    required: false,
  },
  {
    type: 'referenceSelect',
    component: EntityFieldReferenceSelect,
    name: 'categoryId',
    label: 'Category',
    hideInView: false,
    hideInList: false,
    hideInEdit: false,
    required: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Name',
    sortable: true,
    filterable: true,
    required: true,
    validator: minMaxLength(
      MIN_NAME_LENGTH,
      MAX_NAME_LENGTH
    )(`Name must be between ${MIN_NAME_LENGTH} and ${MAX_NAME_LENGTH} characters`),
  },
  {
    type: 'string',
    name: 'description',
    label: 'Description',
    sortable: true,
    filterable: true,
    required: true,
    validator: minMaxLength(
      MIN_DESCRIPTION_LENGTH,
      MAX_DESCRIPTION_LENGTH
    )(
      `Description must be between ${MIN_DESCRIPTION_LENGTH} and ${MAX_DESCRIPTION_LENGTH} characters`
    ),
  },
  {
    type: 'custom',
    component: EntityFieldPrice,
    name: 'price',
    label: 'Price',
    sortable: true,
    filterable: true,
    required: true,
  },
  {
    type: 'string',
    name: 'vendor',
    label: 'Vendor',
  },
  {
    type: 'number',
    name: 'wholeSalePrice',
    label: 'Wholesale Price',
    component: WholeSalePrice,
  },
  {
    type: 'datetime',
    name: 'createdAt',
    label: 'Create Date',
    sortable: true,
    hideInCreate: true,
    hideInEdit: true,
    component: EntityDateField,
  },

  // Navigation Fields
  {
    type: 'custom',
    name: ' ',
    component: ProductDeleteButton,
    hideInEdit: true,
    hideInView: true,
    hideInCreate: true,
  },
];
