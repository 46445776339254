import React, { FC, useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Box, Button, FormControl, OutlinedInput } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import styles from './styles.module.scss';
import placeholderImage from './placeholder.png';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';

type IProps = IEntityFieldProps;

const ProductFieldImage: FC<IProps> = ({
  data,
  label,
  value,
  error,
  isEditing,
  onClick,
  options,
  handler,
}: IProps) => {
  const [innerError] = useState('');
  const previewValue = useMemo(
    function () {
      return data?.imageURL || value;
    },
    [data, value]
  );
  const url = useMemo(
    function () {
      return typeof previewValue?.name == 'string'
        ? URL.createObjectURL(previewValue)
        : previewValue;
    },
    [previewValue]
  );
  const match = useRouteMatch<{ id: string }>();
  const isViewing = !isEditing && typeof match.params.id != 'undefined';

  const onChange = useCallback(
    (event) => {
      if (!handler) return;
      console.log(event);

      handler(event.target.files[0]);
    },
    [handler]
  );

  return isEditing ? (
    <div>
      <FormControl
        variant="outlined"
        className={styles.formControl}
        style={{ position: 'relative' }}
      >
        <label htmlFor={label} className={styles.label}>
          {url ? <div className={styles.image} style={{ backgroundImage: `url('${url}')` }} /> : ''}

          <OutlinedInput
            fullWidth
            style={{
              flexDirection: 'row-reverse',
            }}
            error={!!innerError || !!error}
            className={styles.outlinedInput}
            inputComponent={() => (
              <Button
                name={label}
                startIcon={<ImageIcon fontSize={'large'} />}
                variant="contained"
                style={{
                  display: 'flex',
                  width: 200,
                  minHeight: 54,
                }}
              >
                <span>Upload file</span>
              </Button>
            )}
          />
          <input
            id={label}
            name={label}
            type="file"
            onChange={onChange}
            style={{ position: 'absolute' }}
            accept={options && options.mimes ? options.mimes.join(', ') : ''}
          />
        </label>
        {(innerError || error) && (
          <Box color="error.main" fontSize={12}>
            {innerError || error}
          </Box>
        )}
      </FormControl>
    </div>
  ) : (
    <div onClick={onClick}>
      <img
        src={previewValue ? String(previewValue) : placeholderImage}
        className={isViewing ? styles.preview : styles.image}
        alt="image"
      />
    </div>
  );
};

export { ProductFieldImage };
