import React, {FC} from 'react';
import {IEntityFieldProps} from 'icerockdev-admin-toolkit';

type IProps = IEntityFieldProps;

const OrderProductFieldAmount: FC<IProps> = ({data, options}) => {
  const price = data?.price || 0
  const quantity = data?.quantity || 0
  const amount =
    ((price &&
      parseFloat(price) &&
      parseFloat(parseFloat(price).toFixed(options?.accuracy || 6))) ||
    0) * quantity;

  return (<div>{amount || 0} $</div>);
};

export {OrderProductFieldAmount};

