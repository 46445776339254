import { IEntityCreateFunctionProps, IEntityUpdateFunctionProps } from 'icerockdev-admin-toolkit';
import {
  createInventoryFn,
  getInventoryCategoryFn,
  getInventoryPointFn,
  getInventoryProductFn,
  sendFileFn,
  updateInventoryFn,
} from './api';
import { INVENTORY_ENTITY_FIELDS } from './fields';
import InventoryEntity from './components/InventoryEntity';
import { defaultFetchItemsFn, defaultGetFn } from '~/config/api';

export default (host: string) =>
  new InventoryEntity({
    title: 'Inventory',
    editable: true,
    viewable: true,
    creatable: true,
    exportable: true,
    selectable: false,
    api: {
      list: {
        url: InventoryEntity.getParametrizedUrl(host, '/v1/point/:pointId/inventory'),
        method: 'get',
      },
      get: {
        url: InventoryEntity.getParametrizedUrl(host, '/v1/point/:pointId/inventory'),
        method: 'get',
      },
      create: {
        url: InventoryEntity.getParametrizedUrl(host, '/v1/point/:pointId/inventory'),
        method: 'post',
      },
      update: {
        url: InventoryEntity.getParametrizedUrl(host, '/v1/point/:pointId/inventory'),
        method: 'put',
      },
      delete: {
        url: InventoryEntity.getParametrizedUrl(host, '/v1/point/:pointId/inventory'),
        method: 'delete',
      },
      uploadFile: {
        url: InventoryEntity.getParametrizedUrl(host, '/v1/point/:pointId/inventory'),
        method: 'post',
      },
    },
    menu: {
      enabled: false,
      label: '-',
      url: '/point/:pointId/inventory',
    },
    references: {
      productCatalogId: {
        getMany: getInventoryProductFn,
        getOne: getInventoryProductFn,
      },
      categoryId: {
        getMany: getInventoryCategoryFn,
        getOne: getInventoryCategoryFn,
      },
      pointId: {
        getMany: getInventoryPointFn,
        getOne: getInventoryPointFn,
      },
    },
    fields: INVENTORY_ENTITY_FIELDS,
    sendFileFn,
    fetchItemsFn: defaultFetchItemsFn,
    getItemsFn: defaultGetFn,
    createItemsFn: ({ url, token, data }: IEntityCreateFunctionProps) =>
      createInventoryFn({ url, token, data }),
    updateItemsFn: ({ url, id, token, data }: IEntityUpdateFunctionProps) =>
      updateInventoryFn({ url, id, token, data }),
  });
