import {
  Entity,
  IEntityCreateFunction,
  IEntityGetFunction,
  IEntityUpdateFunction,
  UNAUTHORIZED
} from 'icerockdev-admin-toolkit';
import Axios from 'axios';
import HttpStatusCode from 'http-status-typed';
import {parseResponseErrorMessage} from "~/utils/error";
import {jwtExpired} from "~/utils/catcher";

const AUTOCOMPLETE_LIMIT = 9999;

export const createPointFn: IEntityCreateFunction = async ({url, token, data}) => {
  const requestBody = {
    buildingId: data.buildingId,
    name: data.name,
    tax: data.tax,
    delivery: data.delivery,
  }

  if (data.delivery) {
    validateDeliveryTime(data?.deliveryStartAt, data?.deliveryEndAt)

    requestBody['deliveryStartAt'] = data?.deliveryStartAt
    requestBody['deliveryEndAt'] = data?.deliveryEndAt
  }

  const response = await Axios.post(url, requestBody, {
    headers: {
      authorization: token,
    },
  }).catch((e) => e.response);

  if (response?.status === HttpStatusCode.UNAUTHORIZED) return {data: {}, error: UNAUTHORIZED};
  if (response?.status !== HttpStatusCode.OK || !response?.data) {
    const error = parseResponseErrorMessage(response) || `Can't create point`;

    return {data: {}, error};
  }

  return {
    data: response.data.data,
    error: '',
  };
}

export const updatePointFn: IEntityUpdateFunction = async ({url, id, token, data}) => {
  const requestBody = {
    name: data.name,
    tax: data.tax,
    delivery: data.delivery,
  }

  if (data.delivery) {
    validateDeliveryTime(data?.deliveryStartAt, data?.deliveryEndAt)

    requestBody['deliveryStartAt'] = data.deliveryStartAt
    requestBody['deliveryEndAt'] = data.deliveryEndAt
  }

  const response = await Axios.put(`${url}/${data.id}`, requestBody, {
    headers: {
      authorization: token,
    },
  }).catch((e) => e.response);

  if (response?.status === HttpStatusCode.UNAUTHORIZED) return {data: {}, error: UNAUTHORIZED};
  if (response?.status !== HttpStatusCode.OK || !response?.data) {
    const error = parseResponseErrorMessage(response) || `Can't update point`;

    return {data: {}, error};
  }

  return {
    data: response.data.data,
    error: '',
  };
}

export const getPointBuildingFn = async (entity: Entity): Promise<Record<string, any>> => {
  const url = `${process.env.REACT_APP_API_URL}/v1/building`;
  const response = await entity.parent!.auth!.withToken(
    ({token}) =>
      Axios.get(url, {params: {limit: AUTOCOMPLETE_LIMIT}, headers: {authorization: token}}),
    {}
  );

  if (!response?.data?.data) return {};

  return response.data.data.reduce((acc, building) => ({...acc, [building.id]: building.name}), {});
};


const validateDeliveryTime = (startAt: string, endAt: string) => {
  if (!startAt || !endAt) {
    throw new Error('Delivery start & end time are required if delivery flag selected.');
  }

  if (startAt > endAt) {
    throw new Error('Delivery end time can\'t be grater then start time.');
  }
};

export const setStatusPointFn = async ({url, token}) => {
  const result = await Axios
    .put(url, {}, {headers: {authorization: token}})
    .catch(jwtExpired);

  if (result?.status !== HttpStatusCode.OK || !result?.data) {
    const error = parseResponseErrorMessage(result) || `Error set status point`;

    return {data: {}, error};
  }

  return {
    data: result.data,
  };
};


export const deletePointFn = async ({url, token}) => {
  const result = await Axios
    .delete(url, {headers: {authorization: token}})
    .catch(jwtExpired);

  if (result?.status !== HttpStatusCode.OK || !result?.data) {
    const error = parseResponseErrorMessage(result) || `Error delete point`;

    return {data: {}, error};
  }

  return {
    data: result.data,
  };
};

