import React, {FC, useEffect, useState} from 'react';
import QRCode from 'qrcode';
import {IEntityFieldProps} from 'icerockdev-admin-toolkit';
import styles from "./styles.module.scss";

type IProps = IEntityFieldProps;
type QrState = {
  url: string | undefined;
}

const OrderProductFieldQr: FC<IProps> = (
  ({
     value,
     onClick
   }: IProps) => {
    const [qr, setQr] = useState<QrState>({url: undefined});

    const generateQR = async (text): Promise<string | undefined> => {
      try {
        return await QRCode.toDataURL(text)
      } catch (err) {
        console.error(err)
        return undefined
      }
    }

    useEffect(() => {
      generateQR(value).then(url => setQr({url: url}))
    }, [value]);


    return (<div onClick={onClick}>
      <img
        src={qr.url}
        className={styles.image}
        alt="qr"/>
    </div>);
  }
);

export {OrderProductFieldQr};

