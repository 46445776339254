import { Config } from 'icerockdev-admin-toolkit';
import building from '../entity/building';
import inventory from '../entity/inventory';
import point from '../entity/point';
import category from '../entity/category';
import product from '../entity/product';
import customer from '../entity/customer';
import order from '../entity/order';
import auth from './auth';
import { VerticalLayout } from '~/config/application/layout/VerticalLayout';
import { computed } from 'mobx';

export const BASE_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.11:8080/admin';

export class ProntoConfig extends Config {
  @computed
  get linksForCurrentUser() {
    let newList = [...this.pagesForCurrentUser];
    if (this.auth?.user.role === 'ASSOCIATE') {
      newList = newList.filter((page) => page.title === 'Orders' || page.title === 'Residents');
    }
    return newList.map((page) => ({
      name: page.menu.label,
      url: page.menu.url,
      enabled: page.menu.enabled,
      childFields: page.menu.childFields,
    }));
  }
}

export default new ProntoConfig({
  auth: auth,
  title: 'Pronto',
  name: 'Pronto',
  pages: [
    building(BASE_URL),
    inventory(BASE_URL),
    point(BASE_URL),
    category(BASE_URL),
    product(BASE_URL),
    customer(BASE_URL),
    order(BASE_URL),
  ],
  logo: '',
  layout: VerticalLayout,
});
