import React, {FC, useEffect, useState} from 'react';
import QRCode from 'qrcode';
import {useRouteMatch} from 'react-router';
import {IEntityFieldProps} from 'icerockdev-admin-toolkit';
import styles from "~/entity/inventory/components/InventoryFieldQr/styles.module.scss";

type IProps = IEntityFieldProps;
type QrState = {
  url: string | undefined;
}

const InventoryFieldQr: FC<IProps> = (
  ({
     value,
     isEditing,
     onClick
   }: IProps) => {
    const [qr, setQr] = useState<QrState>({url: undefined});
    const match = useRouteMatch<{ id: string }>();
    const isViewing = !isEditing && typeof match.params.id != 'undefined';

    const generateQR = async (text): Promise<string | undefined> => {
      try {
        return await QRCode.toDataURL(text)
      } catch (err) {
        console.error(err)
        return undefined
      }
    }

    useEffect(() => {
      generateQR(value).then(url => setQr({url: url}))
    }, [value]);


    return isEditing ? (
      <div>&nbsp;</div>
    ) : (
      <div onClick={onClick}>
        <img
          src={qr.url}
          className={isViewing ? styles.preview : styles.image}
          alt="qr"/>
      </div>
    );
  }
);

export {InventoryFieldQr};

