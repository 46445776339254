import React, {FC} from 'react';
import {observer} from 'mobx-react';
import styles from './styles.module.scss';
import {useEntity} from 'icerockdev-admin-toolkit';
import {OrderCancelButton} from "~/entity/order/components/OrderCancelButton";

interface IProps {
}

const OrderViewButtons: FC<IProps> = observer(() => {
  const entity = useEntity();

  return (
    <div className={styles.wrap}>
      <OrderCancelButton data={entity?.editorData}/>
    </div>
  );
});

export {OrderViewButtons};
