import {
  EMPTY_USER,
  JWTAuthProvider,
  UNAUTHORIZED,
  WithTokenFunction,
} from 'icerockdev-admin-toolkit';
import { tokenRefreshFn } from '~/config/auth/api';
import { SignInWrapper } from '~/config/components/auth/SignInWrapper';
import { action, flow, observable } from 'mobx';
import { axios } from '~/utils/axios';
import { API_URLS } from '~/config/constants/urls';

const EMPTY_TOKENS = {
  access: '',
  refresh: '',
};

export class JWTWithSmsAuthProvider extends JWTAuthProvider {
  @observable loginPhone = '';
  @observable loginToken = '';

  @action
  sendAuthRequest = (phone: string, password: string) => {
    this.sendAuthRequestCancel();

    this.sendAuthRequestInstance = flow(function* sendAuthRequest(this: JWTWithSmsAuthProvider) {
      if (!this.authRequestFn) return;

      this.isLoading = true;

      try {
        const response = yield axios.post(API_URLS.auth.signIn, { phone });

        this.parent?.notifications.hideNotification();

        if (!response?.data?.data?.token) throw new Error(`Can't login`);

        this.loginPhone = phone;
        this.loginToken = response.data.data.token;
        this.parent?.history.push(`/confirm`);
      } catch (e) {
        this.error = e;
        this.parent?.notifications.showError(e.toString());
      } finally {
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  sendLoginConfirm = async (code) => {
    try {
      const token = this.loginToken;
      const result = await axios.post(API_URLS.auth.signInConfirm, {
        token,
        code,
      });

      if (!result?.data?.data?.accessToken || !result?.data?.data?.refreshToken) {
        throw new Error(`Can't login, please try again`);
      }

      this.tokens = {
        access: result.data.data.accessToken,
        refresh: result.data.data.refreshToken,
      };

      this.loginToken = '';
      this.loginPhone = '';

      await this.getUserProfile();
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    }
  };

  @action
  resendLoginCode = async () => {
    try {
      if (!this.loginPhone) throw new Error('No phone number available, please, log in again');

      const result = await axios.post(API_URLS.auth.signInResendSms, { phone: this.loginPhone });

      if (!result?.data?.data?.confirmationToken)
        throw new Error(`Can't resend sms code, please try to log in again`);

      this.loginToken = result.data.data.confirmationToken;

      this.parent?.notifications.showSuccess(`New sms code was sent to your phone number`);
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    }
  };

  @action
  getUserProfile = async () => {
    try {
      const result = await this.withToken(
        ({ token }) => axios.get(API_URLS.auth.profile, { headers: { authorization: token } }),
        {}
      );

      if (!result?.data?.data) return;

      this.user = result.data.data;
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    }
  };

  @action
  withToken: WithTokenFunction = async (req: any, args: any) =>
    req({
      ...args,
      token: `Bearer ${this.tokens.access}`,
    })
      .then((result: any) => {
        if (result?.error === UNAUTHORIZED) {
          throw new Error(UNAUTHORIZED);
        }
        return result;
      })
      .catch(async (e: Error) => {
        if (e?.message.indexOf('401') !== -1 || e?.message.indexOf('400') !== -1) {
          this.user = EMPTY_USER;
          this.tokens = EMPTY_TOKENS;
          return e;
        }
        if (!this.tokenRefreshFn) {
          console.warn('tokenRefreshFn not specified');
          throw new Error(e.message);
        }
        // If there's other updater, wait for it
        if (!this.tokenRefreshInstance) {
          this.tokenRefreshInstance = flow(function* (this: JWTAuthProvider) {
            if (!this.tokenRefreshFn) return { access: '', refresh: '' };

            return yield this.tokenRefreshFn(this.tokens.refresh);
          }).bind(this)();

          try {
            const tokens = await this.tokenRefreshInstance;
            this.tokens = {
              access: tokens.access || '',
              refresh: tokens.refresh,
            };
          } catch (err) {
            this.user = EMPTY_USER;
            this.tokens = EMPTY_TOKENS;
            throw err;
          }

          this.tokenRefreshInstance = null;
        } else {
          await this.tokenRefreshInstance;
        }

        if (this.tokens.access && this.tokens.refresh) {
          return req({
            ...args,
            token: `Bearer ${this.tokens.access}`,
          });
        }

        this.user = EMPTY_USER;
        this.tokens = EMPTY_TOKENS;

        return e;
      });
}

export default new JWTWithSmsAuthProvider({
  loginLabel: '7xxxxxxxxxx',
  authRequestFn: () => Promise.resolve({} as any),
  tokenRefreshFn,
  getUserName: (auth) => auth.user.name,
  getUserRoleTitle: (auth) => auth.user.phone || '',
  signIn: SignInWrapper,
});
