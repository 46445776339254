import { IEntityProps } from 'icerockdev-admin-toolkit';
import { action, computed, reaction } from 'mobx';
import React from 'react';
import { PointViewButtons } from '~/entity/point/components/PointViewButtons';
import { POINT_STATUSES } from '~/entity/point/constants';
import { deletePointFn, setStatusPointFn } from '~/entity/point/api';
import CustomEntity from '~/entity/CustomEntity';

export default class PointEntity extends CustomEntity {
  constructor(fields?: Partial<IEntityProps>) {
    super(fields as any);
    reaction(
      () => this.editorData?.buildingId,
      () => this.updateBuildingOptionsOne()
    );
  }

  @action
  updateBuildingOptionsMany = async () => {
    if (!this.references.buildingId.getMany) return;

    const results = await this.references.buildingId.getMany(this);

    Object.assign(this.referenceData, { buildingId: results });
  };

  @action
  updateBuildingOptionsOne = async () => {
    if (!this.references.buildingId.getOne) return;

    const results = await this.references.buildingId.getOne(this);

    Object.assign(this.referenceData, { buildingId: results });
  };

  @action
  setStatusPoint = async (id: number, status: keyof typeof POINT_STATUSES) => {
    if (!this.parent?.auth?.withToken) return;

    this.isLoading = true;
    const url = `${this?.api?.update.url}/${id}/${status}`;

    await this.parent.auth.withToken(setStatusPointFn, { url });
    await this.fetchItems();

    this.isLoading = false;
  };

  @action
  deletePoint = async (id: any) => {
    try {
      if (!this.parent?.auth?.withToken) return;

      this.isLoading = true;
      const url = `${this?.api?.delete.url}/${id}`;

      await this.parent.auth.withToken(deletePointFn, { url });
      await this.fetchItems();

      this.parent?.history.push('/point');
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    }
  };

  @computed
  get ViewerHeadButtons() {
    return () => <PointViewButtons />;
  }
}
