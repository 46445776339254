import React, { FC } from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { useEntity } from 'icerockdev-admin-toolkit';
import { CustomerBlockButton } from "~/entity/customer/components/CustomerBlockButton";
import { CustomerAnonymizeButton } from "~/entity/customer/components/CustomerAnonymizeButton";

interface IProps {
}

const CustomerViewButtons: FC<IProps> = observer(() => {
  const entity = useEntity();

  return (
    <div className={styles.wrap}>
      <CustomerAnonymizeButton data={entity?.editorData}/>
      <CustomerBlockButton id={entity.editorData.id} status={entity.editorData.status}/>
    </div>
  );
});

export { CustomerViewButtons };
