import React, { FC } from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { ProductDeleteButton } from '~/entity/product/components/ProductDeleteButton';
import { useEntity } from 'icerockdev-admin-toolkit';

interface IProps {}

const ProductViewButtons: FC<IProps> = observer(() => {
  const entity = useEntity();

  return (
    <div className={styles.wrap}>
      <ProductDeleteButton data={entity?.editorData} />
    </div>
  );
});

export { ProductViewButtons };
