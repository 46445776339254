import { Entity } from 'icerockdev-admin-toolkit';
import Axios from 'axios';
import { axios } from '~/utils/axios';
import Papa from 'papaparse';
import moment from 'moment';

const AUTOCOMPLETE_LIMIT = 9999;

export const getOrderPointFn = async (entity: Entity): Promise<Record<string, any>> => {
  const url = `${process.env.REACT_APP_API_URL}/v1/point`;
  const response = await entity.parent!.auth!.withToken(
    ({ token }) =>
      Axios.get(url, { params: { limit: AUTOCOMPLETE_LIMIT }, headers: { authorization: token } }),
    {}
  );

  if (!response?.data?.data) return {};

  return response.data.data.reduce((acc, point) => ({ ...acc, [point.id]: point.name }), {});
};

export const getOrderCustomerFn = async (entity: Entity): Promise<Record<string, any>> => {
  const url = `${process.env.REACT_APP_API_URL}/v1/customer`;
  const response = await entity.parent!.auth!.withToken(
    ({ token }) =>
      Axios.get(url, { params: { limit: AUTOCOMPLETE_LIMIT }, headers: { authorization: token } }),
    {}
  );

  if (!response?.data?.data) return {};

  return response.data.data.reduce(
    (acc, customer) => ({
      ...acc,
      [customer.id]: `${customer.firstName} ${customer.lastName}`,
    }),
    {}
  );
};

export const getOrderProductListCategoriesFn = async (
  entity: Entity
): Promise<Record<string, any>> => {
  const url = `${process.env.REACT_APP_API_URL}/v1/category`;
  const response = await entity.parent!.auth!.withToken(
    ({ token }) =>
      Axios.get(url, { params: { limit: AUTOCOMPLETE_LIMIT }, headers: { authorization: token } }),
    {}
  );

  if (!response?.data?.data) return {};

  return response.data.data.reduce(
    (acc, category) => ({
      ...acc,
      [category.id]: category.name,
    }),
    {}
  );
};

export const exportOrder = async (withToken, params): Promise<void> => {
  if (!withToken) return;
  const url = `${process.env.REACT_APP_API_URL}/v1/order/export`;
  try {
    const result = await withToken(
      ({ token }) =>
        axios.get(url, {
          headers: { authorization: token },
          params,
        }),
      {}
    );
    let newList;
    let updatedList;

    if (!result.data) return;
    Papa.parse(result.data, {
      header: true,
      complete: (results) => {
        newList = results.data;
      },
    });
    if (newList) {
      updatedList = newList.map((el) => {
        const date = moment(el['Created Date']).local().format('DD/MM/YYYY HH:mm');
        return { ...el, 'Created Date': date };
      });
    }

    const newData = Papa.unparse(updatedList);

    const type = result.headers['content-type'];
    const blob = new Blob([newData], { type: type });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'orders.csv';
    link.click();
  } catch (e) {
    console.error(e);
  }
};
