import { IEntityProps } from 'icerockdev-admin-toolkit';
import { action, computed } from 'mobx';
import { axios } from '~/utils/axios';
import { jwtExpired } from '~/utils/catcher';
import { OrderEntityViewer } from '~/entity/order/components/OrderEntityViewer';
import { observer } from 'mobx-react';
import React from 'react';
import { OrderViewButtons } from '~/entity/order/components/OrderViewButtons';
import CustomEntity from '~/entity/CustomEntity';
import ExportDropdown from '../ExportDropdown/ExportDropdown';

export default class OrderEntity extends CustomEntity {
  constructor(fields?: Partial<IEntityProps>) {
    super(fields as any);
  }

  @action
  cancelOrder = async (id: any) => {
    try {
      if (!process.env.REACT_APP_API_URL) throw new Error('Specify api url at .env!');

      const url = `${process.env.REACT_APP_API_URL}/v1/order/${id}`;

      await this?.parent?.auth
        ?.withToken(({ token }) => axios.delete(url, { headers: { authorization: token } }), {})
        .catch(jwtExpired);

      this.fetchItems();
      this.parent?.history.push('/order');
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    }
  };

  @computed
  get ViewerBody() {
    return observer(({ id }: { id: string }) => (
      <OrderEntityViewer
        id={id}
        fields={this.fields}
        url={this.menu.url}
        errors={this.editorFieldErrors}
        onSave={() => {}}
        onCancel={this.onEditCancel}
        onResetFieldError={this.resetFieldError}
        isEditing={false}
        isLoading={this.isLoading}
        setEditorData={this.setEditorData}
        data={this.editorData}
        getItem={this.getItem}
        cancelGetItem={this.getItemsCancel}
        withToken={this.parent?.auth?.withToken}
        viewable={this.viewable}
        entity={this}
      />
    ));
  }

  @computed
  get ListHeadButtons() {
    return observer(() => (
      <ExportDropdown selectedList={this.selected} withToken={this.parent?.auth?.withToken} />
    ));
  }

  @computed
  get ViewerHeadButtons() {
    return () => <OrderViewButtons />;
  }
}
