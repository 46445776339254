import { IEntityProps } from 'icerockdev-admin-toolkit';

export const ENTITY_ERRORS = {
  CANT_UPDATE_ITEM: `Can't update item`,
  CANT_LOAD_ITEMS: `Can't load items`,
  FIELD_IS_REQUIRED: 'Required field',
  INCORRECT_INPUT: 'Check all fields',
};

export interface ISendFileProps {
  url: string;
  token?: string;
  file: File;
}

export interface ISendFileResult {
  data: Record<string, any>;
  error?: string;
}

export type ISendFileFunction = (props: ISendFileProps) => Promise<ISendFileResult>;
