import { action, computed, flow, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { Entity, IEntityProps } from 'icerockdev-admin-toolkit';
import { EntityFooter } from '~/config/components/common/EntityFooter';
import { EntityHead } from '~/config/components/common/EntityHead';
import { ENTITY_ERRORS } from '~/utils/types';
import { IEntityCreateFunctionResult } from 'icerockdev-admin-toolkit';
import { EntityViewer } from '~/config/components/common/EntityViewer';
import { EntityBreadcrumbs } from '~/config/components/common/EntityBreadcrumbs';

export default class CustomEntity extends Entity {
  constructor(fields?: Partial<IEntityProps>) {
    super();

    if (fields) {
      Object.assign(this, fields);
    }
  }

  @action
  createItem = () => {
    this.updateItemInstance = flow(function* (this: Entity) {
      this.isLoading = true;
      this.error = '';

      try {
        const data = toJS(this.editorData);

        if (!this.validateSubmitFields(data, true)) {
          throw new Error(ENTITY_ERRORS.INCORRECT_INPUT);
        }

        if (!this.api?.create?.url || !this.createItemsFn) {
          throw new Error(ENTITY_ERRORS.CANT_LOAD_ITEMS);
        }

        const result: IEntityCreateFunctionResult = yield this.parent?.auth?.withToken(
          this.createItemsFn,
          {
            url: this.api?.create?.url || '',
            data,
          }
        );

        if (!result || result.error)
          throw new Error(result?.error || ENTITY_ERRORS.CANT_LOAD_ITEMS);

        this.fetchItems();
        this.parent?.history.push(this.menu.url);
      } catch (e) {
        this.error = e;
        this.parent?.notifications.showError(e.message);
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  validateSubmitFields = (data: Record<string, any>, isCreating = false): boolean => {
    this.editorFieldErrors = this.fields
      .filter((field) => (isCreating && !field.hideInCreate) || (!isCreating && !field.hideInEdit))
      .reduce(
        (obj, field) =>
          this.isValidField(field, data[field.name])
            ? obj
            : {
                ...obj,
                [field.name]:
                  (field.validator && field.validator(data[field.name], this)) ||
                  ENTITY_ERRORS.FIELD_IS_REQUIRED,
              },
        {}
      );

    return Object.keys(this.editorFieldErrors).length === 0;
  };

  @computed
  get Breadcrumbs() {
    return observer(
      ({
        id,
        isEditing = false,
        isCreating = false,
        buttons,
      }: {
        id?: any;
        isEditing?: boolean;
        isCreating?: boolean;
        buttons?: ReactElement;
      }) => (
        <EntityBreadcrumbs
          data={this.editorData}
          fields={this.fields}
          id={id}
          name={this.title}
          url={this.menu.url}
          isEditing={isEditing}
          isCreating={isCreating}
          buttons={buttons}
          viewable={this.viewable}
          editable={this.canEdit}
        />
      )
    );
  }

  @computed
  get EditorBody() {
    return observer(({ id }: { id: string }) => (
      <EntityViewer
        id={id}
        fields={this.fields}
        errors={this.editorFieldErrors}
        url={this.menu.url}
        onSave={this.updateItem}
        onCancel={this.onEditCancel}
        onResetFieldError={this.resetFieldError}
        isLoading={this.isLoading}
        setEditorData={this.setEditorData}
        data={this.editorData}
        getItem={this.getItem}
        cancelGetItem={this.getItemsCancel}
        withToken={this.parent?.auth?.withToken}
        viewable={this.viewable}
        entity={this}
        isEditing
      />
    ));
  }

  @computed
  get CreatorBody() {
    this.fields.forEach((item) => this.resetFieldError(item.name));

    return observer(() => (
      <EntityViewer
        fields={this.fields}
        errors={this.editorFieldErrors}
        url={this.menu.url}
        onSave={this.createItem}
        onCancel={this.onEditCancel}
        onResetFieldError={this.resetFieldError}
        isEditing
        isLoading={this.isLoading}
        setEditorData={this.setEditorData}
        data={this.editorData}
        getItem={this.createEmptyItem}
        cancelGetItem={this.getItemsCancel}
        viewable={this.viewable}
        withToken={this.parent?.auth?.withToken}
        entity={this}
      />
    ));
  }

  @computed
  get Creator() {
    return observer(({ id }: { id: string }) => (
      <>
        <this.CreatorHead />
        <this.CreatorBody />
        <this.CreatorFooter />
      </>
    ));
  }

  @computed
  get ListHead() {
    return observer(() => (
      <EntityHead
        filterData={this.filterData}
        title={<this.ListHeadTitle />}
        buttons={<this.ListHeadButtons />}
        filters={this.filters}
        fields={this.fields}
        setFilters={this.setFilters}
        url={this.menu.url}
        applyFilter={this.applyFilter}
        withToken={this.parent?.auth?.withToken}
        onExport={this.exportData}
        canExport={this.exportable}
        canCreate={this.creatable && this.canCreate}
        entity={this}
      />
    ));
  }

  @computed
  get ListFooter() {
    return observer(() => (
      <EntityFooter
        page={this.page}
        itemsPerPage={this.itemsPerPage}
        items={this.items}
        totalCount={this.totalCount}
        setPage={this.setPage}
        setPerPage={this.setPerPage}
      />
    ));
  }
}
