import React, {FC, useCallback, useMemo} from 'react';
import {observer} from 'mobx-react';
import {useEntity} from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';
import {Button} from '@material-ui/core';
import OrderEntity from "~/entity/order/components/OrderEntity";
import {ORDER_STATUS_CANCELED} from "~/entity/order/constants";

interface IProps {
  data: Record<string, any>
}

const OrderCancelButton: FC<IProps> = observer(({data}) => {
  const entity = useEntity<OrderEntity>();

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (!window.confirm('Cancel order?')) return;

      entity.cancelOrder(data?.id);
    },
    [entity.cancelOrder, data?.id]
  );

  const isViewing = useMemo(() => {
    return !window.location.pathname.match(new RegExp(`\/${data?.id}\/?`));
  }, [data?.id]);

  const isCanceled = useMemo(() => {
    return data?.status == ORDER_STATUS_CANCELED;
  }, [data?.status]);

  return isViewing || isCanceled ? (<div>&nbsp;</div>) : (
    <Button onClick={onClick} className={styles.button} color="secondary" variant="outlined">
      Cancel
    </Button>
  );
});

export {OrderCancelButton};
