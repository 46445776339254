import { IEntityField } from 'icerockdev-admin-toolkit';
import { BUILDING_STATUSES } from './constants';
import { minMaxLength } from '~/utils/validators';
import { EntityDateField } from '~/config/components/common/EntityDateField';

const MIN_NAME_LENGTH = 3;
const MAX_NAME_LENGTH = 100;
const MIN_ADDRESS_LENGTH = 5;
const MAX_ADDRESS_LENGTH = 100;

export const BUILDING_ENTITY_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    sortable: true,
    hideInCreate: true,
    hideInEdit: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    options: BUILDING_STATUSES,
    sortable: true,
    filterable: true,
    hideInEdit: true,
    hideInCreate: true,
    required: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Name',
    sortable: true,
    filterable: true,
    required: true,
    validator: minMaxLength(
      MIN_NAME_LENGTH,
      MAX_NAME_LENGTH
    )('Name must be between 3 and 100 characters'),
  },
  {
    type: 'string',
    name: 'address',
    label: 'Address',
    sortable: true,
    filterable: true,
    required: true,
    validator: minMaxLength(
      MIN_ADDRESS_LENGTH,
      MAX_ADDRESS_LENGTH
    )('Address must be between 5 and 100 characters'),
  },
  {
    type: 'phone',
    name: 'phone',
    label: 'Phone',
    sortable: true,
    filterable: true,
    required: true,
  },
  {
    type: 'datetime',
    name: 'createdAt',
    label: 'Create Date',
    sortable: true,
    hideInCreate: true,
    hideInEdit: true,
    component: EntityDateField,
  },
];
