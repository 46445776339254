import React, {FC} from 'react';
import {observer} from 'mobx-react';
import styles from './styles.module.scss';
import {useEntity} from 'icerockdev-admin-toolkit';
import {BuildingDeleteButton} from "~/entity/building/components/BuildingDeleteButton";
import {BuildingBlockButton} from "~/entity/building/components/BuildingBlockButton";

interface IProps {
}

const BuildingViewButtons: FC<IProps> = observer(() => {
  const entity = useEntity();

  return (
    <div className={styles.wrap}>
      <BuildingBlockButton id={entity.editorData.id} status={entity.editorData.status} />
      <BuildingDeleteButton data={entity?.editorData}/>
    </div>
  );
});

export {BuildingViewButtons};
