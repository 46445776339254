import { action, computed } from 'mobx';
import React from 'react';
import { BuildingViewButtons } from '~/entity/building/components/BuildingViewButtons';
import { deleteBuildingFn, setStatusBuildingFn } from '~/entity/building/api';
import { BUILDING_STATUSES } from '~/entity/building/constants';
import CustomEntity from '~/entity/CustomEntity';

export default class BuildingEntity extends CustomEntity {
  @action
  setStatusBuilding = async (id: number, status: keyof typeof BUILDING_STATUSES) => {
    if (!this.parent?.auth?.withToken) return;

    this.isLoading = true;
    const url = `${this?.api?.update.url}/${id}/${status}`;

    await this.parent.auth.withToken(setStatusBuildingFn, { url });
    await this.fetchItems();

    this.isLoading = false;
  };

  @action
  deleteBuilding = async (id: any) => {
    try {
      if (!this.parent?.auth?.withToken) return;

      this.isLoading = true;
      const url = `${this?.api?.delete.url}/${id}`;

      await this.parent.auth.withToken(deleteBuildingFn, { url });
      await this.fetchItems();

      this.parent?.history.push('/building');
    } catch (e) {
      this.isLoading = false;
      this.parent?.notifications.showError(e.message);
    }
  };

  @computed
  get ViewerHeadButtons() {
    return () => <BuildingViewButtons />;
  }
}
