import React, {FC, useCallback, useMemo} from 'react';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {IEntityFieldProps} from 'icerockdev-admin-toolkit';
import {Link} from '@material-ui/core';

type IProps = IEntityFieldProps;

const PointInventoryLinkField: FC<IProps> = (
  ({data, isEditing}: IProps) => {
    const history = useHistory();
    const pointId = useMemo(function () {
      return data?.id || null
    }, [data]);

    const onClick = useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        history.push(`/point/${pointId}/inventory`);
      },
      [pointId]
    );

    return isEditing && pointId != null ? (<div>&nbsp;</div>) :
      (<div>
        <Link
          onClick={onClick}
          to={`/point/${pointId}/inventory`}
          component={RouterLink}
        >Navigate</Link>
      </div>);
  }
);

export {PointInventoryLinkField};

