import CustomEntity from '~/entity/CustomEntity';
import { action, computed } from 'mobx';
import { anonymizeCustomerFn, setStatusCustomerFn } from '../../api';
import { CUSTOMER_STATUSES } from '~/entity/customer/constants';
import React from 'react';
import { CustomerViewButtons } from '~/entity/customer/components/CustomerViewButtons';

class CustomerEntity extends CustomEntity {
  @action
  setStatus = async (id: number, status: keyof typeof CUSTOMER_STATUSES) => {
    try {
      if (!this.parent?.auth?.withToken) return;

      this.isLoading = true;

      await this.parent.auth.withToken(setStatusCustomerFn, {
        url: `${this?.api?.status.url}/${id}/${status}`,
      });
      await this.fetchItems();

      this.parent?.history.push('/customer');
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    }
  };

  @action
  anonymize = async (id: number) => {
    try {
      if (!this.parent?.auth?.withToken) return;

      this.isLoading = true;

      await this.parent.auth.withToken(anonymizeCustomerFn, {
        url: `${this?.api?.anonymize.url}/${id}/anonymize`,
      });
      await this.fetchItems();

      this.parent?.history.push('/customer');
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    }
  };

  @computed
  get ViewerHeadButtons() {
    return () => <CustomerViewButtons />;
  }
}

export { CustomerEntity };
