export const CUSTOMER_STATUS_ACTIVE = 'ACTIVE'
export const CUSTOMER_STATUS_BLOCKED = 'BLOCKED'
export const CUSTOMER_STATUS_REGISTRATION = 'REGISTRATION'

const CUSTOMER_STATUSES = {
  [CUSTOMER_STATUS_ACTIVE]: 'Active',
  [CUSTOMER_STATUS_BLOCKED]: 'Blocked',
  [CUSTOMER_STATUS_REGISTRATION]: 'Registration'
};

export {CUSTOMER_STATUSES}

export const APARTMENT_NONE = "NONE"
