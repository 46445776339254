import React, { FC, FormEvent, useCallback, useMemo, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import { useConfig } from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';

const SignIn: FC = () => {
  const config = useConfig();
  const auth = config.auth;

  const [value, handler] = useState('');

  const onSubmitCapture = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      auth!!.sendAuthRequest(value, '');
    },
    [value, auth]
  );

  const loginLabel = useMemo(() => config.auth?.loginLabel || 'Login', [config.auth]);

  const onChange = useCallback(
    (event) => {
      if (!handler) return;

      handler(event.target.value.replace(/[^\d]/gim, ''));
    },
    [handler]
  );

  return (
    <div className={styles.wrap}>
      <form noValidate onSubmit={onSubmitCapture} className={styles.form}>
        <h3 className={styles.header}>Sign In</h3>

        <InputMask
          mask="+9 (999) 999-99-99"
          value={value ? value.toString() : ''}
          onChange={onChange}
        >
          {() => (
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="phone"
              label={loginLabel}
              name="phone"
              autoComplete="value"
              defaultValue={value}
              autoFocus
            />
          )}
        </InputMask>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!value.length}
          className={styles.button}
        >
          Log in
        </Button>

        {!!auth?.authSignupFn && (
          <Button
            type="button"
            fullWidth
            variant="outlined"
            color="primary"
            className={styles.button}
            component={Link}
            to="/signup"
          >
            Sign up
          </Button>
        )}
      </form>
    </div>
  );
};

export { SignIn };
