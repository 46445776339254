import React, {FC, useCallback} from 'react';
import {useEntity} from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import BuildingEntity from "~/entity/building/components/BuildingEntity";
import {BUILDING_STATUS_ACTIVE, BUILDING_STATUS_BLOCKED, BUILDING_STATUSES} from "~/entity/building/constants";

interface IProps {
  id: any;
  status: keyof typeof BUILDING_STATUSES;
  small?: boolean;
}

const BuildingBlockButton: FC<IProps> = ({id, status, small}) => {
  const entity = useEntity<BuildingEntity>();

  const setStatus = useCallback(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();

      const newStatus = status === BUILDING_STATUS_BLOCKED
        ? BUILDING_STATUS_ACTIVE
        : BUILDING_STATUS_BLOCKED

      if (newStatus === BUILDING_STATUS_BLOCKED) {
        if (!window.confirm('Block building?')) return;
      }

      await entity.setStatusBuilding(id || 0, newStatus);
      entity.setEditorData({...entity.editorData, status: newStatus});
    },
    [id, entity, status]
  );

  return (
    small ? (
      <div className={styles.wrap}>
        <IconButton
          color="primary"
          aria-label="set status"
          component="span"
          style={{color: status !== BUILDING_STATUS_ACTIVE ? 'red' : 'green'}}
          onClick={setStatus}
        >
          <PowerSettingsNewIcon/>
        </IconButton>
      </div>
    ) : (
      <Button color="secondary" variant="outlined" onClick={setStatus}>
        {status === BUILDING_STATUS_ACTIVE ? 'Block' : 'Unblock'}
      </Button>
    )
  );
};

export {BuildingBlockButton};
