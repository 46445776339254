import { isEmail as isEmailValidator } from 'validator';
import { isEmpty } from 'ramda';

export const isPositive = (val: any) =>
  val && parseFloat(val) && parseFloat(val) > 0
    ? ''
    : 'The value must be greater than 0';

export const isPositiveOrZero = (val: any) =>
  !isEmpty(val) && Number.isFinite(parseFloat(val)) && parseFloat(val) >= 0
    ? ''
    : 'The value must be greater than or equal to 0';

export const isPositiveOrEmpty = (val: any) =>
  !val || (parseFloat(val) && parseFloat(val) > 0)
    ? ''
    : 'The value must be greater than 0, or not specified';

export const isPositiveOrZeroOrEmpty = (val: any) =>
    val === null || typeof val === "undefined" || isEmpty(val) || (Number.isFinite(parseFloat(val)) && parseFloat(val) >= 0)
    ? ''
    : 'The value must be greater than, or equal to 0, or unspecified';

export const isIntegerOrEmpty = (val: any) =>
    val === null || typeof val === "undefined" || isEmpty(val) || Number(val) === parseInt(val, 10)
    ? ''
    : 'The value must be an integer';

export const isNumberOrEmpty = (val: any) =>
    val === null || typeof val === "undefined" || isEmpty(val) || Number(val) === parseFloat(val)
    ? ''
    : 'The value must be a number';

export const minMax = (min: number, max: number) => (message: string) => (val: number) => {
  return !val || val < min || val > max ? message : '';
};

export const minMaxLength = (min: number, max: number) => (message: string) => (val: string) => {
  if (!val?.length) { val = "" }
  const condition = min === 0 ? false : !val;
  return condition || val.length < min || val.length > max ? message : '';
};

export const isEmail = (message: string) => (val: string) => {
  return val && isEmailValidator(val) ? '' : message;
};
