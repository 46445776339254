import React, {FC} from "react";
import styles from './styles.module.scss';

type IProps = {
  data: Record<string, string>[];
}

const OrderEntityTotal: FC<IProps> = ({data}) => {
  const format = (number) => Math.trunc(number * 100) / 100
  const subtotal = format(data["subtotal"] || 0)
  const taxPercent = data["tax"] || 0
  const tax = format((subtotal * taxPercent) / 100)
  const total = format(data["total"] || 0)

  return (<div className={styles.wrap}>
    <div>
      <span>Subtotal:</span>
      <span>{subtotal} $</span>
    </div>
    <div>
      <span>Tax:</span>
      <span>{tax} $</span>
    </div>
    <div>
      <span>Total:</span>
      <span>{total} $</span>
    </div>
  </div>)
};

export {OrderEntityTotal}

