import React, {FC,} from 'react';
import styles from './styles.module.scss';
import placeholderImage from './placeholder.png';
import {IEntityFieldProps} from "icerockdev-admin-toolkit";

type IProps = IEntityFieldProps;

const OrderFieldImage: FC<IProps> = (
  ({
     value,
     onClick
   }: IProps) => {
    return (<div onClick={onClick}>
      <img
        src={value ? String(value) : placeholderImage}
        className={styles.image}
        alt="image"/>
    </div>);
  }
);

export {OrderFieldImage};
