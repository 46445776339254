import {IEntityFetchFunction, IEntityGetFunction, UNAUTHORIZED} from "icerockdev-admin-toolkit";
import Axios from "axios";
import qs from "query-string";
import HttpStatusCode from "http-status-typed";
import {parseResponseErrorMessage} from "~/utils/error";

export const defaultFetchItemsFn: IEntityFetchFunction
  = async ({
             url,
             token,
             page = 0,
             count,
             sortDir,
             sortBy,
             filter,
           }) => {
  const offset = page && count ? page * count : 0;
  const limit = count ? (page + 1) * count : 25;
  const orderBy = sortDir.toUpperCase();
  // noinspection TypeScriptValidateTypes
  const filters: Record<string, string> =
    filter?.reduce((obj, item) => ({...obj, [item.name]: item.value}), {}) || {};

  const response = await Axios.get(url, {
    params: {
      offset,
      limit,
      ...(sortBy ? {sortBy, orderBy} : {}),
      ...filters,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, {arrayFormat: 'separator'});
    },
    headers: {authorization: token},
  }).catch((e) => e.response);

  if (response && response.status === HttpStatusCode.UNAUTHORIZED) {
    return {
      data: {
        list: [],
        totalCount: 0,
      },
      error: UNAUTHORIZED,
    };
  }

  if (response?.status !== HttpStatusCode.OK || !response?.data) {
    return {
      data: {list: []},
      error: parseResponseErrorMessage(response) || `Can't fetch item`,
    };
  }

  const list = response.data.data;

  return {
    data: {
      list,
      totalCount: parseInt(response.headers['x-total-count'] || 0, 10),
    },
    filterData: (list && list[0]) || {},
    error: '',
  };
};

export const defaultGetFn: IEntityGetFunction = async ({id, url, token}) => {
  const response = await Axios.get(`${url}/${id}`, {
    headers: {
      authorization: token,
    },
  }).catch((e) => e.response);

  if (!response || response.status === HttpStatusCode.UNAUTHORIZED) {
    return {
      data: {},
      error: UNAUTHORIZED,
    };
  }

  if (response?.status !== HttpStatusCode.OK || !response?.data)
    return {
      data: {},
      error: parseResponseErrorMessage(response) || `Can't fetch item`,
    };

  return {
    data: response.data.data,
    error: '',
  };
};

