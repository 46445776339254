import {
  Entity,
  IEntityCreateFunction,
  IEntityUpdateFunction,
  UNAUTHORIZED,
} from 'icerockdev-admin-toolkit';
import Axios from 'axios';
import HttpStatusCode from 'http-status-typed';
import { parseResponseErrorMessage } from '~/utils/error';
import { ISendFileProps, ISendFileResult } from '~/utils/types';
import { jwtExpired } from '~/utils/catcher';

const AUTOCOMPLETE_LIMIT = 9999;

export const createInventoryFn: IEntityCreateFunction = async ({ url, token, data }) => {
  const requestBody = {
    productCatalogId: data.productCatalogId,
    price: data.price,
    quantity: data.quantity,
  };

  const response = await Axios.post(url, requestBody, {
    headers: {
      authorization: token,
    },
  }).catch((e) => e.response);

  if (response?.status === HttpStatusCode.UNAUTHORIZED) return { data: {}, error: UNAUTHORIZED };
  if (response?.status !== HttpStatusCode.OK || !response?.data) {
    const error = parseResponseErrorMessage(response) || `Can't create inventory`;

    return { data: {}, error };
  }

  return {
    data: response.data.data,
    error: '',
  };
};

export const updateInventoryFn: IEntityUpdateFunction = async ({ url, id, token, data }) => {
  const requestBody = {
    status: data.status,
    price: data.price,
    quantity: data.quantity,
  };

  const response = await Axios.put(`${url}/${data.id}`, requestBody, {
    headers: {
      authorization: token,
    },
  }).catch((e) => e.response);

  if (response?.status === HttpStatusCode.UNAUTHORIZED) return { data: {}, error: UNAUTHORIZED };
  if (response?.status !== HttpStatusCode.OK || !response?.data) {
    const error = parseResponseErrorMessage(response) || `Can't update inventory`;

    return { data: {}, error };
  }

  return {
    data: response.data.data,
    error: '',
  };
};

export const getInventoryProductFn = async (entity: Entity): Promise<Record<string, any>> => {
  const url = `${process.env.REACT_APP_API_URL}/v1/product`;
  const response = await entity.parent!.auth!.withToken(
    ({ token }) =>
      Axios.get(url, { params: { limit: AUTOCOMPLETE_LIMIT }, headers: { authorization: token } }),
    {}
  );

  if (!response?.data?.data) return {};

  return response.data.data.reduce(
    (acc, product) => ({
      ...acc,
      [product.id]: { name: product.name, categoryId: product.categoryId, price: product?.price },
    }),
    {}
  );
};

export const getInventoryCategoryFn = async (entity: Entity): Promise<Record<string, any>> => {
  const url = `${process.env.REACT_APP_API_URL}/v1/category`;
  const response = await entity.parent!.auth!.withToken(
    ({ token }) =>
      Axios.get(url, { params: { limit: AUTOCOMPLETE_LIMIT }, headers: { authorization: token } }),
    {}
  );

  if (!response?.data?.data) return {};

  return response.data.data.reduce(
    (acc, category) => ({ ...acc, [category.id]: category.name }),
    {}
  );
};

export const getInventoryPointFn = async (entity: Entity): Promise<Record<string, any>> => {
  const url = `${process.env.REACT_APP_API_URL}/v1/point`;
  const response = await entity.parent!.auth!.withToken(
    ({ token }) =>
      Axios.get(url, { params: { limit: AUTOCOMPLETE_LIMIT }, headers: { authorization: token } }),
    {}
  );

  if (!response?.data?.data) return {};

  return response.data.data.reduce((acc, point) => ({ ...acc, [point.id]: point.name }), {});
};

export const sendFileFn = async ({
  url,
  token,
  file,
}: ISendFileProps): Promise<ISendFileResult> => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await Axios.post(`${url}/import`, formData, {
    headers: { 'Content-Type': 'multipart/form-data', authorization: token },
  }).catch(jwtExpired);

  if (response?.status === HttpStatusCode.UNAUTHORIZED) return { data: {}, error: UNAUTHORIZED };
  if (response?.status !== HttpStatusCode.OK || !response?.data) {
    const error = parseResponseErrorMessage(response) || `Can't send file`;

    return { data: {}, error };
  }

  return {
    data: response.data.data,
    error: '',
  };
};
