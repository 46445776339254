import {
  Entity,
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityUpdateFunctionProps,
  IEntityUpdateFunctionResult,
  UNAUTHORIZED
} from 'icerockdev-admin-toolkit';
import Axios from 'axios';
import HttpStatusCode from 'http-status-typed';
import {parseResponseErrorMessage} from "~/utils/error";
import {APARTMENT_NONE} from "~/entity/customer/constants";
import {jwtExpired} from "~/utils/catcher";

export const updateCustomerFn = async ({ url, id, token, data }: IEntityUpdateFunctionProps): Promise<IEntityUpdateFunctionResult> => {
  const result = await Axios.put(
      `${url}/${data.id}`,
      {
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        email: data.email,
        buildingId: data.buildingId,
        apartmentNumber: data.apartmentNumber || APARTMENT_NONE,
      },
      {
        headers: { authorization: token },
      }
  ).catch(jwtExpired);

  if (!result || result.status === HttpStatusCode.UNAUTHORIZED) {
    return {
      data: {
        list: [],
        totalCount: 0,
      },
      error: UNAUTHORIZED,
    };
  }

  if (result?.status !== HttpStatusCode.OK || !result?.data)
    return {
      data: {
        list: [],
        totalCount: 0,
      },
      error: parseResponseErrorMessage(result) || `Can't update customer`,
    };

  return {
    data: result.data.data,
    error: '',
  };
};

export const createCustomerFn = async ({url,token,data,}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {

    const result = await Axios.post(
        url,
        {
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            email: data.email,
            buildingId: data.buildingId,
            apartmentNumber: data.apartmentNumber || APARTMENT_NONE,
        },
        {
            headers: { authorization: token },
        }
    ).catch(jwtExpired);

    if (result?.status === HttpStatusCode.UNAUTHORIZED) return { data: {}, error: UNAUTHORIZED };
    if (result?.status !== HttpStatusCode.OK || !result?.data) {
        const error = parseResponseErrorMessage(result) || `Can't create customer`;

        return { data: {}, error };
    }

    return {
        data: result.data,
    };
};

export const fetchBuildingFn = (host: string, url: string) => async (entity: Entity): Promise<Record<string, string>> => {
    const withToken = entity.parent?.auth?.withToken;

    if (!withToken) return {};

    const response = await withToken(
        ({ token }) =>
            Axios.get(`${host}${url}`, {
                params: {
                    offset: 0,
                    limit: 9999,
                },
                headers: { authorization: token },
            }).catch(jwtExpired),
        {}
    );

    if (!response?.data?.data || !response.data.data.length) return {};

    return response.data.data
        .sort((a: { name: string; }, b: { name: any; }) => a.name.localeCompare(b.name))
        .reduce(
            (obj: Record<string, string>, item: { name: string; id: number }) => ({
                ...obj,
                [item.id]: item.name,
            }),
            {}
        );
};

export const setStatusCustomerFn = async ({url, token}) => {
  const result = await Axios
    .put(url, {}, {headers: {authorization: token}})
    .catch(jwtExpired);

  if (result?.status !== HttpStatusCode.OK || !result?.data) {
    const error = parseResponseErrorMessage(result) || `Error set status customer`;

    return {data: {}, error};
  }

  return {
    data: result.data,
  };
};

export const anonymizeCustomerFn = async ({url, token,}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
    const result = await Axios.put(url, {},{
        headers: { authorization: token },
    }).catch(jwtExpired);

    if (result?.status === HttpStatusCode.UNAUTHORIZED) return { data: {}, error: UNAUTHORIZED };
    if (result?.status !== HttpStatusCode.OK || !result?.data) {
        const error = parseResponseErrorMessage(result) || `Can't anonymize customer`;

        return { data: {}, error };
    }

    return {
        data: result.data,
    };
};
