import { IEntityCreateFunctionProps, IEntityUpdateFunctionProps } from 'icerockdev-admin-toolkit';
import { createProductFn, getProductCategoryFn, sendFileFn, updateProductFn } from './api';
import { PRODUCT_ENTITY_FIELDS } from './fields';
import ProductEntity from './components/ProductEntity';
import { defaultFetchItemsFn, defaultGetFn } from '~/config/api';

export default (host: string) =>
  new ProductEntity({
    title: 'Products',
    editable: true,
    viewable: true,
    creatable: true,
    exportable: false,
    selectable: false,
    api: {
      list: { url: `${host}/v1/product`, method: 'get' },
      get: { url: `${host}/v1/product`, method: 'get' },
      create: { url: `${host}/v1/product`, method: 'post' },
      update: { url: `${host}/v1/product`, method: 'put' },
      delete: { url: `${host}/v1/product`, method: 'delete' },
      uploadFile: { url: `${host}/v1/product`, method: 'post' },
    },
    menu: {
      enabled: true,
      label: 'Products',
      url: '/product',
    },
    references: {
      categoryId: {
        getMany: getProductCategoryFn,
        getOne: getProductCategoryFn,
      },
    },
    fields: PRODUCT_ENTITY_FIELDS,
    sendFileFn,
    fetchItemsFn: defaultFetchItemsFn,
    getItemsFn: defaultGetFn,
    createItemsFn: ({ url, token, data }: IEntityCreateFunctionProps) => {
      return createProductFn({ url, token, data, file: getImageFile(data) });
    },
    updateItemsFn: ({ id, url, token, data }: IEntityUpdateFunctionProps) => {
      return updateProductFn({ id, url, token, data, file: getImageFile(data) });
    },
  });

const getImageFile = (data: Record<string, any>): File => data?.imageFile || new File([''], '');
