import {
  Entity,
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityUpdateFunctionProps,
  IEntityUpdateFunctionResult,
  UNAUTHORIZED,
} from 'icerockdev-admin-toolkit';
import Axios from 'axios';
import HttpStatusCode from 'http-status-typed';
import { parseResponseErrorMessage } from '~/utils/error';
import { jwtExpired } from '~/utils/catcher';
import { ISendFileProps, ISendFileResult } from '~/utils/types';

const AUTOCOMPLETE_LIMIT = 9999;

type ICreateFunctionProps = IEntityCreateFunctionProps & { file: File };

export const createProductFn = async ({
  url,
  token,
  data,
  file,
}: ICreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const requestBody = {
    name: data.name,
    description: data.description,
    categoryId: data.categoryId,
    price: data.price,
    quantity: data.quantity,
    vendor: data.vendor,
    wholeSalePrice: data.wholeSalePrice,
  };

  const formData = new FormData();
  formData.append('data', JSON.stringify(requestBody));
  formData.append('image', file);

  const response = await Axios.post(url, formData, {
    headers: { authorization: token },
  }).catch(jwtExpired);

  if (response?.status === HttpStatusCode.UNAUTHORIZED) return { data: {}, error: UNAUTHORIZED };
  if (response?.status !== HttpStatusCode.OK || !response?.data) {
    const error = parseResponseErrorMessage(response) || `Can't create product`;

    return { data: {}, error };
  }

  return {
    data: response.data.data,
    error: '',
  };
};

type IUpdateFunction = IEntityUpdateFunctionProps & { file: File };

export const updateProductFn = async ({
  id,
  url,
  token,
  data,
  file,
}: IUpdateFunction): Promise<IEntityUpdateFunctionResult> => {
  const requestBody = {
    status: data.status,
    name: data.name,
    description: data.description,
    categoryId: data.categoryId,
    price: data.price,
    quantity: data.quantity,
    vendor: data.vendor,
    wholeSalePrice: data.wholeSalePrice,
  };

  const formData = new FormData();
  formData.append('data', JSON.stringify(requestBody));
  formData.append('image', file);

  const response = await Axios.put(`${url}/${data.id}`, formData, {
    headers: { authorization: token },
  }).catch(jwtExpired);

  if (response?.status === HttpStatusCode.UNAUTHORIZED) return { data: {}, error: UNAUTHORIZED };
  if (response?.status !== HttpStatusCode.OK || !response?.data) {
    const error = parseResponseErrorMessage(response) || `Can't update category`;

    return { data: {}, error };
  }

  return {
    data: response.data.data,
    error: '',
  };
};

export const getProductCategoryFn = async (entity: Entity): Promise<Record<string, any>> => {
  const url = `${process.env.REACT_APP_API_URL}/v1/category`;
  const response = await entity.parent!.auth!.withToken(
    ({ token }) =>
      Axios.get(url, { params: { limit: AUTOCOMPLETE_LIMIT }, headers: { authorization: token } }),
    {}
  );

  if (!response?.data?.data) return {};

  return response.data.data.reduce(
    (acc, category) => ({ ...acc, [category.id]: category.name }),
    {}
  );
};

export const sendFileFn = async ({
  url,
  token,
  file,
}: ISendFileProps): Promise<ISendFileResult> => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await Axios.post(`${url}/import`, formData, {
    headers: { 'Content-Type': 'multipart/form-data', authorization: token },
  }).catch(jwtExpired);

  if (response?.status === HttpStatusCode.UNAUTHORIZED) return { data: {}, error: UNAUTHORIZED };
  if (response?.status !== HttpStatusCode.OK || !response?.data) {
    const error = parseResponseErrorMessage(response) || `Can't send file`;

    return { data: {}, error };
  }

  return {
    data: response.data.data,
    error: '',
  };
};
