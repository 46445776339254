import { IEntityField } from 'icerockdev-admin-toolkit';
import { INVENTORY_STATUSES } from './constants';
import { EntityFieldReferenceSelect } from '~/config/components/common/EntityFieldReferenceSelect';
import { InventoryFieldQr } from '~/entity/inventory/components/InventoryFieldQr';
import { InventoryDeleteButton } from '~/entity/inventory/components/InventoryDeleteButton';
import { InventoryFieldImage } from '~/entity/inventory/components/InventoryFieldImage';
import { InventoryProductFieldReferenceSelect } from '~/entity/inventory/components/InventoryProductFieldReferenceSelect';
import { EntityFieldPrice } from '~/config/components/common/EntityFieldPrice';
import { EntityDateField } from '~/config/components/common/EntityDateField';

export const INVENTORY_ENTITY_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    sortable: true,
    hideInCreate: true,
    hideInEdit: true,
  },
  {
    type: 'custom',
    component: InventoryFieldImage,
    name: 'imageURL',
    label: 'Image',
    filterable: false,
    sortable: false,
    required: false,
    hideInCreate: true,
    hideInEdit: true,
  },
  {
    type: 'referenceSelect',
    component: EntityFieldReferenceSelect,
    name: 'pointId',
    label: 'Pronto point',
    hideInView: true,
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true,
    required: true,
  },
  {
    type: 'referenceSelect',
    component: EntityFieldReferenceSelect,
    name: 'categoryId',
    label: 'Category',
    hideInView: false,
    hideInList: false,
    hideInEdit: true,
    required: true,
  },
  {
    type: 'referenceSelect',
    component: InventoryProductFieldReferenceSelect,
    name: 'productCatalogId',
    label: 'Product',
    hideInView: true,
    hideInList: true,
    hideInEdit: true,
    required: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Name',
    sortable: true,
    filterable: true,
    hideInEdit: true,
    hideInCreate: true,
    required: false,
  },
  {
    type: 'string',
    name: 'description',
    label: 'Description',
    sortable: true,
    filterable: true,
    hideInEdit: true,
    hideInCreate: true,
    required: false,
  },
  {
    type: 'custom',
    component: EntityFieldPrice,
    name: 'price',
    label: 'Price',
    sortable: true,
    filterable: true,
    required: true,
  },
  {
    type: 'custom',
    component: InventoryFieldQr,
    name: 'qr',
    label: 'QR',
    filterable: false,
    sortable: false,
    required: false,
    hideInCreate: true,
    hideInEdit: true,
  },
  {
    type: 'number',
    name: 'quantity',
    label: 'Quantity',
    sortable: true,
    filterable: true,
    required: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    options: INVENTORY_STATUSES,
    sortable: true,
    filterable: true,
    hideInEdit: false,
    hideInCreate: true,
    required: true,
  },
  {
    type: 'datetime',
    name: 'createdAt',
    label: 'Create Date',
    sortable: true,
    hideInCreate: true,
    hideInEdit: true,
    component: EntityDateField,
  },

  // Navigation Fields
  {
    type: 'custom',
    name: ' ',
    component: InventoryDeleteButton,
    hideInEdit: true,
    hideInView: true,
    hideInCreate: true,
    hideInExport: true,
  },
];
