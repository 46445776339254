import React, { FC, useCallback } from 'react';
import { TablePagination, WithStyles, withStyles } from '@material-ui/core';
import styles from './styles';

type IProps = WithStyles<typeof styles> & {
  itemsPerPage: number[];
  items: number;
  totalCount: number;
  page: number;
  setPage: (count: number) => void;
  setPerPage: (count: number) => void;
};

const labelDisplayedRows = (page: number, items: number) => ({
  from,
  to,
  count,
}: {
  from: number;
  to: number;
  count: number;
}) => `Page ${page + 1} of ${Math.ceil(count / items)}, Results ${from}-${to} of ${count}`;

const EntityFooterUnconnected: FC<IProps> = ({
  classes,
  totalCount,
  page,
  itemsPerPage,
  items,
  setPage,
  setPerPage,
}) => {
  const onChangeRowsPerPage = useCallback((event) => setPerPage(parseInt(event.target.value)), [
    setPerPage,
  ]);

  const onChangePage = useCallback((_, newPage) => setPage(newPage), [setPage]);

  return (
    <TablePagination
      rowsPerPageOptions={itemsPerPage}
      component="div"
      count={totalCount}
      labelRowsPerPage="Showing:"
      rowsPerPage={items}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      className={classes.pager}
      labelDisplayedRows={labelDisplayedRows(page, items)}
    />
  );
};

const EntityFooter = withStyles(styles)(EntityFooterUnconnected);

export { EntityFooter };
