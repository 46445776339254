import React, { FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { Button, TextField } from '@material-ui/core';
import styles from './styles.module.scss';

interface IProps {
  id: string;
  onConfirm: (code: string) => any;
  onResend: (id: string) => any;
}

const SmsCodeConfirm: FC<IProps> = observer(({ id, onConfirm, onResend }) => {
  const [code, setCode] = useState('');
  const history = useHistory();

  const onCodeChange = useCallback(
    (event) => {
      setCode(event.target.value);
    },
    [setCode]
  );

  const onCancel = useCallback(() => {
    if (!window.confirm('Do you really want to log out?')) return;
    history.push('/');
  }, [history.push]);

  const onResendClicked = useCallback(() => {
    onResend(id);
  }, [id, onResend]);

  const onSubmitClicked = useCallback(
    (event) => {
      event.preventDefault();
      onConfirm(code);
    },
    [code]
  );

  return (
    <form className={styles.wrap} onSubmit={onSubmitClicked}>
      <h2>Enter sms code:</h2>

      <InputMask
        mask="9999"
        value={code ? code.toString() : ''}
        onChange={onCodeChange}
      >
        {() => (
          <TextField defaultValue={code} />
        )}
      </InputMask>

      <Button className={styles.button} color="primary" variant="contained" type="submit">
        OK
      </Button>

      {/* Maybe need later */}
      {/*<Button*/}
      {/*  className={styles.resend}*/}
      {/*  color="primary"*/}
      {/*  variant="outlined"*/}
      {/*  onClick={onResendClicked}*/}
      {/*  type="button"*/}
      {/*>*/}
      {/*  Resend*/}
      {/*</Button>*/}

      <Button
        className={styles.resend}
        color="primary"
        variant="outlined"
        onClick={onCancel}
        type="button"
      >
        Cancel
      </Button>
    </form>
  );
});

export { SmsCodeConfirm };
