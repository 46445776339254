import { IEntityField } from 'icerockdev-admin-toolkit';
import { CUSTOMER_STATUSES } from './constants';
import { EntityFieldReferenceSelect } from '~/config/components/common/EntityFieldReferenceSelect';
import { isEmail, minMaxLength } from '~/utils/validators';
import { EntityDateField } from '~/config/components/common/EntityDateField';

const MIN_NAME_LENGTH = 3;
const MAX_NAME_LENGTH = 100;
const MIN_LAST_NAME_LENGTH = 3;
const MAX_LAST_NAME_LENGTH = 100;

export const CUSTOMER_ENTITY_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    sortable: true,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    options: CUSTOMER_STATUSES,
    sortable: true,
    filterable: true,
    hideInView: false,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'firstName',
    label: 'First Name',
    sortable: true,
    filterable: true,
    required: true,
    validator: minMaxLength(
      MIN_NAME_LENGTH,
      MAX_NAME_LENGTH
    )('First name must be between 3 and 100 characters'),
  },
  {
    type: 'string',
    name: 'lastName',
    label: 'Last Name',
    sortable: true,
    filterable: true,
    required: true,
    validator: minMaxLength(
      MIN_LAST_NAME_LENGTH,
      MAX_LAST_NAME_LENGTH
    )('Last name must be between 3 and 100 characters'),
  },
  {
    type: 'phone',
    name: 'phone',
    label: 'Phone',
    sortable: true,
    filterable: true,
    required: true,
  },
  {
    type: 'referenceSelect',
    component: EntityFieldReferenceSelect,
    name: 'buildingId',
    label: 'Building',
    sortable: true,
    filterable: true,
    required: true,
  },
  {
    type: 'string',
    name: 'email',
    label: 'Email',
    sortable: true,
    filterable: true,
    required: true,
    validator: isEmail('Incorrect email address'),
  },
  {
    type: 'string',
    name: 'apartmentNumber',
    label: 'Apartment Number',
    sortable: false,
    filterable: false,
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    hideInList: true,
  },
  {
    type: 'boolean',
    name: 'hasCard',
    label: 'Attached card',
    sortable: false,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'datetime',
    name: 'createdAt',
    label: 'Create Date',
    sortable: true,
    hideInEdit: true,
    hideInCreate: true,
    hideInList: true,
    hideInView: true,
    component: EntityDateField,
  },
];
