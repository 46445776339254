import { IJWTTokenRefreshFn, UNAUTHORIZED } from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';
import { API_URLS } from '~/config/constants/urls';

export const tokenRefreshFn: IJWTTokenRefreshFn = async (refreshToken) => {
  try {
    const result = await axios.post(API_URLS.auth.refresh, { refreshToken });

    if (!result?.data?.data?.accessToken || !result?.data?.data?.refreshToken) {
      throw new Error(UNAUTHORIZED);
    }
    return {
      access: result.data.data.accessToken,
      refresh: result.data.data.refreshToken,
    };
  } catch (e) {
    console.error('err==>', e);
    return {
      access: '',
      refresh: '',
    };
  }
};
