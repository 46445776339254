import { IEntityProps } from 'icerockdev-admin-toolkit';
import { action, computed, observable, reaction } from 'mobx';
import { jwtExpired } from '~/utils/catcher';
import { axios } from '~/utils/axios';
import React, { ChangeEvent } from 'react';
import { ProductViewButtons } from '~/entity/product/components/ProductViewButtons';
import CustomEntity from '~/entity/CustomEntity';
import { observer } from 'mobx-react';
import { EntityHead } from '~/config/components/common/EntityHead';
import { Button } from '@material-ui/core';
import { ISendFileFunction } from '~/utils/types';

interface IProductEntity extends IEntityProps {
  sendFileFn: ISendFileFunction;
}

export default class ProductEntity extends CustomEntity {
  constructor(fields?: Partial<IProductEntity>) {
    super(fields as any);
    reaction(
      () => this.editorData?.categoryId,
      () => this.updateCategoryOptionsOne()
    );
  }

  @observable sendFileFn: ISendFileFunction | undefined = undefined;

  @action
  sendCSVFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    try {
      if (this.sendFileFn) {
        this.isLoading = true;
        await this.parent?.auth?.withToken(this.sendFileFn, {
          url: this.api?.uploadFile.url,
          file,
        });
        this.parent?.notifications.showSuccess('File successfully uploaded');
        this.fetchItems();
      } else {
        throw Error("sendFileFn wasn't found");
      }
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    } finally {
      this.isLoading = false;
    }
  };

  @action
  updateCategoryOptionsMany = async () => {
    if (!this.references.categoryId.getMany) return;

    const results = await this.references.categoryId.getMany(this);

    Object.assign(this.referenceData, { categoryId: results });
  };

  @action
  updateCategoryOptionsOne = async () => {
    if (!this.references.categoryId.getOne) return;

    const results = await this.references.categoryId.getOne(this);

    Object.assign(this.referenceData, { categoryId: results });
  };

  @action
  deleteProduct = async (id: any) => {
    try {
      if (!process.env.REACT_APP_API_URL) throw new Error('Specify api url at .env!');

      const url = `${process.env.REACT_APP_API_URL}/v1/product/${id}`;

      await this?.parent?.auth
        ?.withToken(({ token }) => axios.delete(url, { headers: { authorization: token } }), {})
        .catch(jwtExpired);

      this.fetchItems();
      this.parent?.history.push('/product');
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    }
  };

  @computed
  get ViewerHeadButtons() {
    return () => <ProductViewButtons />;
  }

  @computed
  get ListHeadButtons() {
    return observer(() => (
      <div style={{ marginRight: '10px' }}>
        <input
          style={{ display: 'none' }}
          accept="text/csv"
          id="upload-button-file"
          type="file"
          onChange={(e) => this.sendCSVFile(e)}
        />

        <label htmlFor="upload-button-file">
          <Button variant="contained" color="primary" component="span">
            Upload
          </Button>
        </label>
      </div>
    ));
  }

  @computed
  get ListHead() {
    return observer(() => (
      <>
        <EntityHead
          filterData={this.filterData}
          title={<this.ListHeadTitle />}
          buttons={<this.ListHeadButtons />}
          filters={this.filters}
          fields={this.fields}
          setFilters={this.setFilters}
          url={this.menu.url}
          applyFilter={this.applyFilter}
          withToken={this.parent?.auth?.withToken}
          onExport={this.exportData}
          canExport={this.exportable}
          canCreate={this.creatable && this.canCreate}
          entity={this}
        />
      </>
    ));
  }
}
