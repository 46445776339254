import React, { FC, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useEntity } from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Fingerprint from '@material-ui/icons/Fingerprint';
import { CustomerEntity } from "~/entity/customer/components/CustomerEntity";
import { isEmpty } from "ramda";

interface IProps {
  data: Record<string, any>
}

const CustomerAnonymizeButton: FC<IProps> = observer(({data}) => {
  const entity = useEntity<CustomerEntity>();

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (!window.confirm('Anonymize customer?')) return;

      entity.anonymize(data?.id);
    },
    [entity.anonymize, data?.id]
  );

  const isViewing = useMemo(() => {
    return !window.location.pathname.match(new RegExp(`\/${data?.id}\/?`));
  }, [data?.id]);

  const isAnonymous = useMemo(() => {
    return isEmpty(data?.phone);
  }, [data?.phone]);

  if(isAnonymous) {
    return null
  }

  return isViewing ? (
    <div className={styles.wrap}>
      <IconButton color="primary" aria-label="anonymize" component="span" onClick={onClick}>
        <Fingerprint/>
      </IconButton>
    </div>
  ) : (
    <Button onClick={onClick} className={styles.button} color="secondary" variant="outlined">
      Anonymize (GDPR)
    </Button>
  );
});

export { CustomerAnonymizeButton };
