import React, { FC, useCallback } from 'react';
import { useEntity } from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { CUSTOMER_STATUS_ACTIVE, CUSTOMER_STATUS_BLOCKED, CUSTOMER_STATUSES } from "~/entity/customer/constants";
import { CustomerEntity } from "~/entity/customer/components/CustomerEntity";

interface IProps {
  id: any;
  status: keyof typeof CUSTOMER_STATUSES;
  small?: boolean;
}

const CustomerBlockButton: FC<IProps> = ({id, status, small}) => {
  const entity = useEntity<CustomerEntity>();

  const setStatus = useCallback(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();

      const newStatus = status === CUSTOMER_STATUS_BLOCKED
        ? CUSTOMER_STATUS_ACTIVE
        : CUSTOMER_STATUS_BLOCKED

      if (newStatus === CUSTOMER_STATUS_BLOCKED) {
        if (!window.confirm('Block customer?')) return;
      }

      await entity.setStatus(id || 0, newStatus);
      entity.setEditorData({...entity.editorData, status: newStatus});
    },
    [id, entity, status]
  );

  return (
    small ? (
      <div className={styles.wrap}>
        <IconButton
          color="primary"
          aria-label="set status"
          component="span"
          style={{color: status !== CUSTOMER_STATUS_ACTIVE ? 'red' : 'green'}}
          onClick={setStatus}
        >
          <PowerSettingsNewIcon/>
        </IconButton>
      </div>
    ) : (
      <Button color="secondary" variant="outlined" onClick={setStatus}>
        {status === CUSTOMER_STATUS_ACTIVE ? 'Block' : 'Unblock'}
      </Button>
    )
  );
};

export { CustomerBlockButton };
