export const ORDER_STATUS_RECEIVED = 'RECEIVED'
export const ORDER_STATUS_PAYMENT_AWAIT = 'PAYMENT_AWAIT'
export const ORDER_STATUS_PAYMENT_SUCCEED = 'PAYMENT_SUCCEED'
export const ORDER_STATUS_PAYMENT_FAILED = 'PAYMENT_FAILED'
export const ORDER_STATUS_CANCELED = 'CANCELED'

const ORDER_STATUSES = {
  [ORDER_STATUS_RECEIVED]: 'Received',
  [ORDER_STATUS_PAYMENT_AWAIT]: 'Await payment',
  [ORDER_STATUS_PAYMENT_SUCCEED]: 'Payment succeed',
  [ORDER_STATUS_PAYMENT_FAILED]: 'Payment failed',
  [ORDER_STATUS_CANCELED]: 'Canceled'
};

export {ORDER_STATUSES}
