import React, {FC} from 'react';
import {observer} from 'mobx-react';
import styles from './styles.module.scss';
import {PointDeleteButton} from '~/entity/point/components/PointDeleteButton';
import {useEntity} from 'icerockdev-admin-toolkit';
import {PointBlockButton} from "~/entity/point/components/PointBlockButton";

interface IProps {
}

const PointViewButtons: FC<IProps> = observer(() => {
  const entity = useEntity();

  return (
    <div className={styles.wrap}>
      <PointBlockButton id={entity.editorData.id} status={entity.editorData.status}/>
      <PointDeleteButton data={entity?.editorData}/>
    </div>
  );
});

export {PointViewButtons};
