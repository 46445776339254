import React, {FC,} from 'react';
import {useRouteMatch} from 'react-router';
import styles from './styles.module.scss';
import placeholderImage from './placeholder.png';
import {IEntityFieldProps} from "icerockdev-admin-toolkit";

type IProps = IEntityFieldProps;

const InventoryFieldImage: FC<IProps> = (
  ({
     value,
     isEditing,
     onClick
   }: IProps) => {
    const match = useRouteMatch<{ id: string }>();
    const isViewing = !isEditing && typeof match.params.id != 'undefined';

    return (<div onClick={onClick}>
      <img
        src={value ? String(value) : placeholderImage}
        className={isViewing ? styles.preview : styles.image}
        alt="image"/>
    </div>);
  }
);

export {InventoryFieldImage};
