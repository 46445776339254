import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

const maxDate = moment().unix();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  })
);

type Props = {
  label: string;
  setChange: (value: string) => void;
  end?: boolean;
};

export default function DatePicker(props: Props) {
  const classes = useStyles();

  return (
    <form className={classes.container} noValidate>
      <TextField
        id="date"
        label={props.label}
        type="date"
        className={classes.textField}
        onChange={(e) => {
          const dateValue = moment(e.target.value).unix();
          if (!(maxDate > dateValue)) return;
          const newDate = props.end
            ? moment(e.target.value).endOf('day').format('yyyy-MM-DD HH:mm:ss')
            : moment(e.target.value).format('yyyy-MM-DD HH:mm:ss');
          props.setChange(newDate);
        }}
        InputProps={{ inputProps: { min: '1111-05-01', max: '2222-05-04' } }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </form>
  );
}
