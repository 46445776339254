import { IEntityField } from 'icerockdev-admin-toolkit';
import { minMaxLength } from '~/utils/validators';
import { EntityDateField } from '~/config/components/common/EntityDateField';

const MIN_NAME_LENGTH = 3;
const MAX_NAME_LENGTH = 50;

export const CATEGORY_ENTITY_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    sortable: true,
    hideInCreate: true,
    hideInEdit: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Name',
    sortable: true,
    filterable: true,
    required: true,
    validator: minMaxLength(
      MIN_NAME_LENGTH,
      MAX_NAME_LENGTH
    )('Name must be between 3 and 50 characters'),
  },
  {
    type: 'datetime',
    name: 'createdAt',
    label: 'Create Date',
    sortable: true,
    hideInCreate: true,
    hideInEdit: true,
    component: EntityDateField,
  },
];
