import React, { FC } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { formatUTCToLocalTime } from '~/utils/formatter';

type IProps = IEntityFieldProps & {};

const EntityDateField: FC<IProps> = ({ value }) => {
  return <div>{formatUTCToLocalTime(value)}</div>;
};

export { EntityDateField };
